import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { environment } from "../environments/environment";
import {
  MatDialogModule,
  MatToolbarModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatTabsModule,
  MatSidenavModule,
  MatDividerModule,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";

import { LoginComponent } from "./login/login.component";
import { InicioComponent } from "./inicio/inicio.component";
import {
  PerfilComponent,
  EditarDatosComponent,
} from "./perfil/perfil.component";
import { NuevoPassComponent } from "./nuevo-pass/nuevo-pass.component";
import { SeleccionCampaniaComponent } from "./seleccion-campania/seleccion-campania.component";

import { VerControlesComponent } from "./ver-controles/ver-controles.component";
import { VerEvolucionesComponent } from "./ver-evoluciones/ver-evoluciones.component";
import { ConfigurarControlesComponent } from "./configurar-controles/configurar-controles.component";
import { CargarEvolucionesComponent } from "./cargar-evoluciones/cargar-evoluciones.component";
import { AgregarControlComponent } from "./agregar-control/agregar-control.component";
import { EliminarControlComponent } from "./eliminar-control/eliminar-control.component";
import { TeleconsultaChatComponent } from "./teleconsulta-chat/teleconsulta-chat.component";
import { TeleconsultasPendientesComponent } from "./teleconsultas-pendientes/teleconsultas-pendientes.component";
import { EliminarTeleconsultaComponent } from "./eliminar-teleconsulta/eliminar-teleconsulta.component";
import { CargarResultadosComponent } from "./cargar-resultados/cargar-resultados.component";
import { NotificacionesComponent } from "./notificaciones/notificaciones.component";

import { MatGridListModule } from "@angular/material/grid-list";
import { ApiService } from "./core/api.service";

import {
  HttpClient,
  HttpHandler,
  HttpClientModule,
} from "@angular/common/http";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { TranslateModule } from "@ngx-translate/core";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { AppConfigModule } from "./app-config.module";
import { TextMaskModule } from "angular2-text-mask";
import { LoginModule } from "./login/login.module";
import { FileSaverModule } from "ngx-filesaver";
import { ModalModule } from "./_modal";
import {
  DialogOverviewExampleDialog,
  RecetaDigitalDialog,
} from "./_modal/modal.service";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "./service/messaging.service";
import { AsyncPipe } from "../../node_modules/@angular/common";
import { DeviceDetectorModule } from "ngx-device-detector";
@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register("./ngsw-worker.js", {
      enabled: environment.production,
    }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    CoreModule,
    SharedModule,
    ReactiveFormsModule,
    AppConfigModule,
    MatIconModule,
    MatMenuModule,
    MatGridListModule,
    MatTabsModule,
    MatSidenavModule,
    MatDividerModule,
    TextMaskModule,
    Ng2SearchPipeModule,
    AppRoutingModule,
    LoginModule,
    FileSaverModule,
    DeviceDetectorModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ModalModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  declarations: [
    AppComponent,
    InicioComponent,
    PerfilComponent,
    EditarDatosComponent,
    NuevoPassComponent,
    TeleconsultaChatComponent,
    SeleccionCampaniaComponent,
    VerControlesComponent,
    VerEvolucionesComponent,
    ConfigurarControlesComponent,
    CargarEvolucionesComponent,
    AgregarControlComponent,
    EliminarControlComponent,
    TeleconsultaChatComponent,
    TeleconsultasPendientesComponent,
    EliminarTeleconsultaComponent,
    CargarResultadosComponent,
    RecetaDigitalDialog,
    NotificacionesComponent,
    DialogOverviewExampleDialog,
  ],
  entryComponents: [
    InicioComponent,
    PerfilComponent,
    EditarDatosComponent,
    SeleccionCampaniaComponent,
    VerControlesComponent,
    VerEvolucionesComponent,
    ConfigurarControlesComponent,
    CargarEvolucionesComponent,
    AgregarControlComponent,
    EliminarControlComponent,
    TeleconsultaChatComponent,
    TeleconsultasPendientesComponent,
    EliminarTeleconsultaComponent,
    CargarResultadosComponent,
    NotificacionesComponent,
  ],

  providers: [
    ApiService,
    HttpClientModule,
    MessagingService,
    AsyncPipe,
    { provide: MAT_DATE_LOCALE, useValue: "es-AR" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
