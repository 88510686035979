import { Component, OnInit } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AgregarControlComponent } from "../agregar-control/agregar-control.component";
import { EliminarControlComponent } from "../eliminar-control/eliminar-control.component";
import { CargarResultadosComponent } from "../cargar-resultados/cargar-resultados.component";

@Component({
  selector: 'app-configurar-controles',
  templateUrl: './configurar-controles.component.html',
  styleUrls: ['./configurar-controles.component.scss']
})
export class ConfigurarControlesComponent implements OnInit {
  url:any;
  id_paciente_campania:any;
  controles_actuales:any;
  id_config_control_detalle:any;
  cargandoControles = true

  constructor(private dialog: MatDialog, private router: Router, activateRoute: ActivatedRoute, protected service: ApiService, private credentialsService: CredentialsService ) {
    this.url = environment.apiPortalSaludEndpoint;
    this.id_paciente_campania = (activateRoute.snapshot.params.id);
    this.buscarControlesActuales();

  }
  ngOnInit() {
  }
  // Funcion para abrir componente para agregar un control
  agregarControl(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Agregar control',
      id_paciente_campania: this.id_paciente_campania,
      id_campania: this.credentialsService.credentials.campania
    };

    const dialogRef = this.dialog.open(AgregarControlComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // Si cerró el componente y creó algun dato buscamos de vuelta la tabla
      this.cargandoControles=true;
      this.buscarControlesActuales();
      this.cargandoControles = false;
    });
  }
  // Function donde buscamos los controles que ya tenga asociado
  buscarControlesActuales(){
    this.service.getData(this.url + '/api/v1/clinica/cli_config_control_detalle_controles_actuales/' + this.id_paciente_campania).subscribe((data: any[]) => {
      this.controles_actuales = data;
      this.cargandoControles = false;
    })
  }

  eliminarControl(id:any){
    this.id_config_control_detalle = id;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Eliminar control',
      id_config_control_detalle: this.id_config_control_detalle,
    };

    const dialogRef = this.dialog.open(EliminarControlComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // Si cerró el componente y creó algun dato buscamos de vuelta la tabla
      this.cargandoControles=true;
      this.buscarControlesActuales();
      this.cargandoControles = false;
    });
  }

  cargarResultado(cli_config_control_detalle:any){
    // En obj recibimos el obj cli config control detalle
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Cargar resultado',
      cli_config_control_detalle: cli_config_control_detalle,
    };

    const dialogRef = this.dialog.open(CargarResultadosComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // Si cerró el componente y creó algun dato buscamos de vuelta la tabla
      this.cargandoControles = true;
      this.buscarControlesActuales();
      this.cargandoControles = false;
    });
  }
}



