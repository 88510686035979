import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { CredentialsService } from '../core';
import { ApiService } from '../core/api.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ModalService } from '../_modal';

@Injectable()
export class MessagingService {
    idUsuarioPortal: any
    urlPortal: string
    data: object
    currentMessage = new BehaviorSubject(null);
    tokenUrlTeleconsulta: any;
    constructor(private modalService: ModalService, private router: Router, private angularFireMessaging: AngularFireMessaging, private credentialsService: CredentialsService, protected service: ApiService) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                // Setemaos el id del usuario del portal
                this.idUsuarioPortal = this.credentialsService.credentials.id_usuario_portal
                // Seteamos la url del portal
                this.urlPortal = environment.apiPortalSaludEndpoint;
                // Comparamos el token creado con el token guardado. Si son diferentes lo actualizamos
                if (token !== this.credentialsService.credentials.token_msj) {
                    // Creamos el objeto con el token
                    // Actualizamos el token en el usuario
                    this.actualizarTokenMsj(token,this.credentialsService.credentials)
                }
            },
            (err) => {
                // alert('no tiene permiso para recibir msj')
                console.error('No tiene permisos para recibir mensajes.', err);
            }
        );
    }
    receiveMessage() {
        this.angularFireMessaging.messaging.subscribe(
            (messaging: any) => {
                messaging.onMessageCallback = (payload: any) => {
                    this.currentMessage.next(payload)
                    // Si esta seteada la variable de receta_digital == 1 llamamos el servicio del modal para mostrar el msj (modal)
                    if (payload.data.receta_digital != undefined && payload.data.receta_digital == 1) {
                        // this.modalService.recetaDigital(payload.data.fecha_pedido)
                    }
                    // Si esta seteada las variables de la telconsulta llamamos el servicio del modal para mostrar el msj (modal)
                    if (payload.data.token_teleconsulta != undefined && payload.data.av_teleconsulta != undefined) {
                        // Seteamos la url de la teleconsulta
                        let token_teleconsulta = payload.data.token_teleconsulta
                        let av_teleconsulta = payload.data.av_teleconsulta
                        // LLamamos el servicio del modal
                        // this.modalService.teleconsulta(token_teleconsulta,av_teleconsulta)
                    }
                }
            }
        )
    }

    // Actualiza el token en el paciente para mandar los mensajes push
    actualizarTokenMsj(token_push, credentials) {

        this.service.getData(this.urlPortal + '/api/v1/clinica/cli_usuarios?where={"nombre_usuario":"' + credentials.usuario+'"}').subscribe((usuario) => {
            if (usuario && usuario.length===0){ // No esta registrado en 'Usuario' en el portal, le creamos el usuario
                let options = {
                    nombre_usuario: credentials.usuario,
                    token_push: token_push,
                    id_profesional: credentials.id_profesional_federado,
                }
                this.service.postData(this.urlPortal + '/api/v1/clinica/new_cli_usuario', options).subscribe(() => {
                })
                this.credentialsService.setToken(token_push);

            } else { // Ya tiene creado el usuario, actualizamos el token
                let id_usuario = usuario[0].id
                let options = {
                    token_push : token_push
                }
                this.service.putData(this.urlPortal + '/api/v1/clinica/cli_usuario', id_usuario, options).subscribe((data: any) => {
                
                })
                this.credentialsService.setToken(token_push)
            }
        })
        
    }
}