import { Injectable, Component, Inject, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

export interface DialogData {
    token: string;
    fechaPedido
  }

@Injectable({ providedIn: 'root' })
export class ModalService {
    token: string
    constructor(private router: Router, public dialog: MatDialog) {}

    // Modal para receta digital
    recetaDigital(fechaPedido) {
        let fechaPedidoPareseada = this.parsearFechas(fechaPedido)
        // Abrimos el modal de la teleconsulta
        const dialogRef = this.dialog.open(RecetaDigitalDialog, {
            data: {fechaPedido: fechaPedidoPareseada}
        });
        dialogRef.afterClosed().subscribe(result => {
            
        });
    }

    // Modal para la teleconsulta
    teleconsulta(token_teleconsulta:string, av_teleconsulta:string): void {  
        // Abrimos el modal de la teleconsulta
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {

        });
        dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['/teleconsulta-chat/'+ av_teleconsulta + '/' + token_teleconsulta])
        });
    }

     // Parsea las fechas de una forma mas legible
  parsearFechas(fecha) {
    let dia = fecha.slice(8, 10)
    let mes = fecha.slice(5, 7)
    let anio = fecha.slice(0, 4)
    let hora = fecha.slice(11,16)
    let fechaParseada = dia + "/" + mes + "/" + anio +' '+hora+' Hs'
    return fechaParseada
  }

}


// ############################################### //
//                                                 //
//               Modal teleconsulta                //
//                                                 //
// ############################################### //

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'dialog-overview-example-dialog.html',
  })
  export class DialogOverviewExampleDialog {
    constructor(private ngZone: NgZone, private router: Router, public dialogRef: MatDialogRef<DialogOverviewExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        timer(5000).subscribe(x => {
            this.onNoClick()
        })
    }
  
    // Si presiona en atender se cierra el modal y redirecciona a teleconsulta-chat
    atenderTeleconsulta(): void {
        this.dialogRef.close()
    }

    onNoClick(): void {
        this.ngZone.run(() => {
            this.dialogRef.close()
        })
    }
  
}

// ############################################### //
//                                                 //
//               Modal receta digital              //
//                                                 //
// ############################################### //

@Component({
    selector: 'recetaDigitalDialog',
    templateUrl: 'recetaDigitalDialog.html',
  })
  export class RecetaDigitalDialog {
    constructor(public dialogRef: MatDialogRef<RecetaDigitalDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        
    }
  

    onNoClick(): void {
        this.dialogRef.close()
    }
    
  
}
  