import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { environment } from 'src/environments/environment';
import { TextMaskModule } from 'angular2-text-mask';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-eliminar-teleconsulta',
  templateUrl: './eliminar-teleconsulta.component.html',
  styleUrls: ['./eliminar-teleconsulta.component.scss']
})
export class EliminarTeleconsultaComponent implements OnInit {
  url: any;
  motivo_baja: any;
  urlCmic:any;
  urlFunsal:any;
  @ViewChild('#motivo_baja_input', { static: false }) motivo_baja_input: ElementRef;
  constructor(activateRoute: ActivatedRoute, private dialog: MatDialog, private dialogRef: MatDialogRef<EliminarTeleconsultaComponent>, @Inject(MAT_DIALOG_DATA) public dataDialog: any, private credentialsService: CredentialsService, protected service: ApiService) {
    this.url = environment.apiPortalSaludEndpoint;
    this.urlCmic = environment.apiCmicEndpoint
    this.urlFunsal = environment.apiFunsalEndpoint
  }

  ngOnInit() {
  }

  close(tipo:any) {
    let resultado = {"tipo":tipo};
    this.dialogRef.close(resultado)
  }


  async rechazarTeleconsulta() {
    let date = new Date();
    let fecha = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
    let min = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes();
    let horas = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours();
    let segundos = (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds();

    let hora = horas + ':' + min;
    let fechaCompletaActual = fecha + " " + hora + ":" + segundos;

    if (this.motivo_baja != undefined && this.motivo_baja != null && this.motivo_baja.length > 0) {

      let datosRechazarTeleconsulta = {
        motivo: this.motivo_baja,
        estado: 3,
        baja_fecha: fechaCompletaActual,
      };

      if (this.dataDialog.tipo == 0){ // Update base cmic
        this.service.putData(this.urlCmic + '/api/v1/clinica/cli_teleconsulta', this.dataDialog.id_teleconsulta, datosRechazarTeleconsulta).subscribe((data: any) => {
          this.close(0);
        })
      } else if (this.dataDialog.tipo == 1){ // Update base cq
        this.service.putData(this.urlFunsal + '/api/v1/clinica/cli_teleconsulta', this.dataDialog.id_teleconsulta, datosRechazarTeleconsulta).subscribe((data: any) => {
          this.close(1);
        })
      } else if (this.dataDialog.tipo == 2){ // Update base portal
        this.service.putData(this.url + '/api/v1/clinica/cli_teleconsulta', this.dataDialog.id_teleconsulta, datosRechazarTeleconsulta).subscribe((data: any) => {
          this.close(2);
        })
      }

    } else {
      await Swal.fire({
        icon: "warning",
        text: "Debe ingresar el motivo",
      });
    }
  }


}
