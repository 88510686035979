import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AgregarControlComponent } from "../agregar-control/agregar-control.component";
import { EliminarControlComponent } from "../eliminar-control/eliminar-control.component";
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cargar-resultados',
  templateUrl: './cargar-resultados.component.html',
  styleUrls: ['./cargar-resultados.component.scss']
})
export class CargarResultadosComponent implements OnInit {
 
  cli_config_control_detalle:any;
  unControl:any;
  urlFunsal:any;
  urlCmic:any;
  urlPortal:any;
  // Variables usadas en la vista
  valor: number
  constructor(activateRoute: ActivatedRoute, private dialog: MatDialog, private dialogRef: MatDialogRef<AgregarControlComponent>, @Inject(MAT_DIALOG_DATA) public dataDialog: any, private credentialsService: CredentialsService, protected service: ApiService) { 
    this.cli_config_control_detalle = this.dataDialog.cli_config_control_detalle;
    this.unControl = this.cli_config_control_detalle.cli_control;
    if (this.unControl.tipo=="radio_varios"){
      let arregloRadio = []
      if (this.cli_config_control_detalle.cli_control.radio_desde != undefined && this.cli_config_control_detalle.cli_control.radio_hasta != undefined) {
        for (let j = this.cli_config_control_detalle.cli_control.radio_desde; j < this.cli_config_control_detalle.cli_control.radio_hasta + 1; j++) {
          const element = arregloRadio.push({ valor: j })
        }
        this.unControl.arregloRadio = arregloRadio;
      }
    }
    
    // URL funsal 
    this.urlFunsal = environment.apiFunsalEndpoint
    // URL cmic
    this.urlCmic = environment.apiCmicEndpoint
    // URL portal
    this.urlPortal = environment.apiPortalSaludEndpoint
  }
  ngOnInit() {
  }
  // Funcion que recibe los controles y prepara los datos para luego crear el resultado
  async subirResultadoControlCampania(resultado) {
    let condicion_salida = false;
    let config_control_detalle = resultado.idConfigControl;
    let tipo_alerta; // 0 sin alerta, 1 alerta roja, 2 alerta amarilla
    if (config_control_detalle.cli_control.tipo === 'input') {
      let el_resultado = parseInt(resultado.resultado);
      if (parseInt(config_control_detalle.minimo_aceptable) < el_resultado && parseInt(config_control_detalle.maximo_aceptable) > el_resultado) {
        tipo_alerta = 0;
      } else if (parseInt(config_control_detalle.minimo_aceptable) === el_resultado || parseInt(config_control_detalle.maximo_aceptable) === el_resultado) {
        tipo_alerta = 2;
      } else {
        tipo_alerta = 1;
      }
    } else if (config_control_detalle.cli_control.tipo === 'radio_varios' || config_control_detalle.cli_control.tipo === 'radio_varios_imagen') {
      let el_resultado = parseInt(resultado.resultado);
      if (parseInt(config_control_detalle.resultado_sin_alerta) === el_resultado) {
        tipo_alerta = 0;
      } else {
        tipo_alerta = 1
      }
    } else if (config_control_detalle.cli_control.tipo === 'checkbox') {
      let el_resultado = (resultado.resultado) ? 1 : 0;
      if (config_control_detalle.resultado_sin_alerta === el_resultado) {
        tipo_alerta = 0;
      } else {
        tipo_alerta = 1;
      }
    } else if (config_control_detalle.cli_control.tipo === 'radio') {
      let el_resultado = parseInt(resultado.resultado);
      if (parseInt(config_control_detalle.resultado_sin_alerta) === el_resultado) {
        tipo_alerta = 0;
      } else {
        tipo_alerta = 1;
      }
    } else {
      tipo_alerta = null;
    }

    // Validamos el resultado que no sea vacio
    let esValido = this.validarCadenaVacia(resultado.resultado, ' ')
    if (esValido) {
      // Cuando es un checkbox seteamos las variables booleanas false y true en 0 y 1 respectivamente
      if (resultado.resultado === false) {
        resultado.resultado = 0
      }
      if (resultado.resultado === true) {
        resultado.resultado = 1
      }
      let arregloResultado = {
        cli_config_control_detalle: config_control_detalle.id, // Seteamos el id config control
        resultado: resultado.resultado, // Resultado
        fecha_resultado: moment().format("YYYY-MM-DD HH:mm:ss"), // Fecha del resultado
        fuera_de_rango: tipo_alerta // Tipo de alerta segun resultado
      } 
      // Creamos el registro control resultado
      await this.crearControlResultado(arregloResultado)
      
    } else {
      await Swal.fire({
        icon: "warning",
        text: "Debe ingresar un resultado",
      });
    }
  }

  // Validador cadena
  // Recibe una cadena y un caracter para contar
  // En nuestro caso el caracter es un espacio.
  // Devuelve true si no es una cadena vacia
  validarCadenaVacia(cadena, caracter) {
    let respuesta: boolean;
    if (cadena == undefined) {
      respuesta = false
    } else {
      let indices = [];
      let cantidadCaracteres = cadena.length;
      for (let i = 0; i < cadena.length; i++) {
        if (cadena[i].toLowerCase() === caracter) indices.push(i);
      }
      if (cantidadCaracteres == indices.length) {
        respuesta = false
      } else {
        respuesta = true
      }
    }
    return respuesta;
  }

  // Crea un resultado control
  async crearControlResultado(arregloResultado) {
    return new Promise((resolve, reject) => {
      this.service.postData(this.urlPortal + '/api/v1/clinica/new_cli_control_resultado', arregloResultado).subscribe(async (data: any) => {
        resolve(data)
        await Swal.fire({
          icon: "success",
          text: "Se ha cargado el resultado correctamente",
        });
        this.close();
      })
    })
  }

  close() {
    this.dialogRef.close()
  }

  


}



