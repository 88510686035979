import { Component, OnInit } from "@angular/core";
import { ApiService } from "../core/api.service";
import {
  Credentials,
  CredentialsService,
} from "../core/authentication/credentials.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import { EliminarTeleconsultaComponent } from "../eliminar-teleconsulta/eliminar-teleconsulta.component";
import { Helpers } from "src/app/helpers.service";

@Component({
  selector: "app-teleconsultas-pendientes",
  templateUrl: "./teleconsultas-pendientes.component.html",
  styleUrls: ["./teleconsultas-pendientes.component.scss"],
})
export class TeleconsultasPendientesComponent implements OnInit {
  nombre: string;
  idcampania: any;
  url: any;
  id_profesional: any;
  teleconsultas: any;
  public Helpers = Helpers;
  cargando: boolean;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    activateRoute: ActivatedRoute,
    protected service: ApiService,
    private credentialsService: CredentialsService
  ) {
    this.url = environment.apiPortalSaludEndpoint;
    this.cargando = true;
    this.nombre = credentialsService.credentials.nombre;
    // this.id_profesional =
    //   credentialsService.credentials.id_profesional_federado;
    this.id_profesional=3;

    this.buscarTeleconsultas();
  }

  ngOnInit() {}

  buscarTeleconsultas() {
    this.service
      .getData(
        this.url +
          "/api/v1/clinica/get_all_teleconsultas/" +
          this.id_profesional
      )
      .subscribe((data: any) => {
        if (data && data.success) {
          this.teleconsultas = data.data;
        }
        this.cargando = false;
      });
  }

  // buscarTeleconsultasPortal() {
  //   this.service
  //     .getData(
  //       this.url +
  //        // '/api/v1/clinica/cli_teleconsultas?where={"idprofesional":' + this.id_profesional +', "baja_fecha":null,"estado":0}'
  //         '/api/v1/clinica/cli_teleconsultas?where={"baja_fecha":null,"estado":0}'
  //     )
  //     .subscribe((data: any[]) => {
  //       this.teleconsultas_pendientes_portal = data;
  //     });
  // }

  /*
  buscarTeleconsultasExternas(){
    this.service.getData(this.url + '/api/v1/clinica/cli_teleconsultas_externas/' +this.id_profesional).subscribe((data: any[]) => {
      this.teleconsultas_pendientes_externas = data;
    })
  }
  */
  // id es id de la teleconsulta, tipo es de donde viene la teleconsulta
  // 0 para avicenna
  // 1 para cq
  // undefined para portal
  rechazarTeleconsulta(id: any, tipo: any) {
    if (tipo == undefined || tipo == undefined || tipo == null) {
      tipo = 2;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: "Rechazar teleconsulta",
      id_teleconsulta: id,
      tipo: tipo,
    };

    const dialogRef = this.dialog.open(
      EliminarTeleconsultaComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.buscarTeleconsultas();
      }
      // Si cerró el componente y creó algun dato buscamos de vuelta la tabla
    });
  }

  generarTeleconsulta(
    id_teleconsulta: any,
    nombre_paciente: any,
    id_paciente: any,
    mail: any,
    token: any,
    token_push: any,
    procedencia: any
  ) {
    let av;
    if (procedencia == 1) {
      av = 0;
    } else if (procedencia == 0) {
      av = 1;
    } else if (procedencia == 2) {
      av = 2;
    }
    // ACORDARSE CAMBIAR
    let link =
      "https://apps.grupocemico.com.ar/teleconsulta/teleconsulta-paciente/index.php?tk=" +
      token +
      "&av=2";

    let datosMail = {
      tipo_mail: 1,
      nombrePaciente: nombre_paciente,
      mail: mail,
      nombreProfesional: this.nombre,
      link: link,
    };

    this.service
      .postData(this.url + "/api/v1/clinica/enviar_mail", datosMail)
      .subscribe((mail_enviado: any) => {
        if (mail_enviado) {
        } else {
        }
      });

    let datosEnviar = {
      token_teleconsulta: token,
      av_teleconsulta: av,
    };

    const datosPush = {
      notificacion_push: {
        title: "Teleconsulta CMIC",
        body: "Su Teleconsulta está lista",
        usuario_envia: this.credentialsService.credentials.usuario,
        token_push_envia: this.credentialsService.credentials.token_msj,
        data: datosEnviar,
        aplicacion: ["portal-medico"],
        to_usuarios: [token_push],
      },
    };

    this.service
      .postData(this.url + "/api/v1/enviar_notificaciones", datosPush)
      .subscribe((notificacion_enviada: any) => {});

    let av_teleconsulta = 2;
    this.router.navigate([
      "/teleconsulta-chat/" +
        token +
        "/" +
        av_teleconsulta +
        "/" +
        id_teleconsulta +
        "/" +
        id_paciente +
        "/" +
        this.id_profesional,
    ]);
  }
}
