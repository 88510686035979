import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../core/api.service";
import {
  Credentials,
  CredentialsService,
} from "../core/authentication/credentials.service";
import { environment } from "src/environments/environment";
import { TextMaskModule } from "angular2-text-mask";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import Swal from "sweetalert2";
import { Helpers } from "src/app/helpers.service";
@Component({
  selector: "app-cargar-evoluciones",
  templateUrl: "./cargar-evoluciones.component.html",
  styleUrls: ["./cargar-evoluciones.component.scss"],
})
export class CargarEvolucionesComponent implements OnInit {
  url: any;
  urlHome:any;
  nombre_profesional: any;
  nombre_paciente: any;
  texto_evolucion: any;
  impresiones_estado_general: any;
  dificultad_habla: any;
  coloracion_cara_labios: any;
  respiracion: any;
  disminucion_orina: any;
  tiene_termometro: any;
  cumple_aislamiento: any;
  vive_solo: any;
  cumplen_aislamiento_convivientes: any;
  red_apoyo: any;
  ficha_informativa_seguimiento_ambulatorio: any;
  id_profesional: any;
  id_paciente_campania: any;
  mandar_a_hisopar: any;
  retirar_kit: any;
  idcampania: any;
  sintomas: any;
  sintomas_cargados: any;
  arreglo_sintomas: Array<any> = [];
  arreglo_agregar_sintomas: Array<any> = [];
  arreglo_eliminar_sintomas: Array<any> = [];
  id_evolucion: any;
  evolucion: any;
  edito_sintomas: any;
  cargando: any;
  urlDiagnosticos: any;
  antecedentes: any;
  texto_tesauro_antecedente: any;
  firstEvolucion: number;
  dar_alta: any;
  motivo_alta: any;
  arreglo_antecedentes: Array<any> = [];
  arreglo_eliminarAntecedentes: Array<any> = [];
  arreglo_agregarAntecedentes: Array<any> = [];
  alertas: any;
  success: boolean;
  @ViewChild("cumplen_aislamiento_convivientes_input", { static: false })
  cumplen_aislamiento_convivientes_input: MatSelect;
  @ViewChild("motivo_alta_input", { static: false })
  motivo_alta_input: ElementRef;

  constructor(
    activateRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CargarEvolucionesComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private credentialsService: CredentialsService,
    protected service: ApiService
  ) {
    this.url = environment.apiPortalSaludEndpoint;
    this.urlHome = environment.apiHomeEndpoint;
    this.urlDiagnosticos = environment.apiItalianoProblemas;
    this.id_profesional =
      credentialsService.credentials.id_profesional_federado;
    this.id_paciente_campania = dataDialog.id_paciente_campania;
    this.nombre_paciente = dataDialog.nombre_paciente;
    this.idcampania = credentialsService.credentials.campania;
    this.nombre_profesional = credentialsService.credentials.nombre;
    this.cargando = true;
    this.success = false;
    this.buscarEvolucionPrevia();
    this.buscarSintomas();
    if (this.dataDialog.id_evolucion != undefined) {
      // Editando la evo
      this.cargando = true;
      this.id_evolucion = this.dataDialog.id_evolucion;
      this.obtenerSintomasCargados();
      this.service
        .getData(
          this.url +
            '/api/v1/clinica/cli_evoluciones_campania?where={"id":' +
            this.id_evolucion +
            "}"
        )
        .subscribe((data: any) => {
          if (data != undefined && data.length > 0) {
            let evolucion = data[0];

            if (typeof evolucion.dificultad_habla !== "undefined") {
              this.dificultad_habla = Helpers.parseNumberToString(
                evolucion.dificultad_habla
              );
            }

            if (typeof evolucion.coloracion_cara_labios !== "undefined") {
              this.coloracion_cara_labios = Helpers.parseNumberToString(
                evolucion.coloracion_cara_labios
              );
            }

            if (
              typeof evolucion.cumplen_aislamiento_convivientes !== "undefined"
            ) {
              this.cumplen_aislamiento_convivientes = Helpers.parseNumberToString(
                evolucion.cumplen_aislamiento_convivientes
              );
            }

            if (typeof evolucion.respiracion !== "undefined") {
              this.respiracion = Helpers.parseNumberToString(
                evolucion.respiracion
              );
            }

            if (typeof evolucion.disminucion_orina !== "undefined") {
              this.disminucion_orina = Helpers.parseNumberToString(
                evolucion.disminucion_orina
              );
            }

            if (typeof evolucion.tiene_termometro !== "undefined") {
              this.tiene_termometro = Helpers.parseNumberToString(
                evolucion.tiene_termometro
              );
            }

            if (typeof evolucion.cumple_aislamiento !== "undefined") {
              this.cumple_aislamiento = Helpers.parseNumberToString(
                evolucion.cumple_aislamiento
              );
            }

            if (typeof evolucion.vive_solo !== "undefined") {
              this.vive_solo = Helpers.parseNumberToString(evolucion.vive_solo);
            }

            if (
              typeof evolucion.cumplen_aislamiento_convivientes !== "undefined"
            ) {
              this.cumplen_aislamiento_convivientes = Helpers.parseNumberToString(
                evolucion.cumplen_aislamiento_convivientes
              );
            }

            if (typeof evolucion.red_apoyo !== "undefined") {
              this.red_apoyo = Helpers.parseNumberToString(evolucion.red_apoyo);
            }

            if (
              typeof evolucion.ficha_informativa_seguimiento_ambulatorio !==
              "undefined"
            ) {
              this.ficha_informativa_seguimiento_ambulatorio = Helpers.parseNumberToString(
                evolucion.ficha_informativa_seguimiento_ambulatorio
              );
            }

            if (typeof evolucion.retirar_kit !== "undefined") {
              this.retirar_kit = Helpers.parseNumberToString(
                evolucion.retirar_kit
              );
            }

            if (typeof evolucion.mandar_a_hisopar !== "undefined") {
              this.mandar_a_hisopar = Helpers.parseNumberToString(
                evolucion.mandar_a_hisopar
              );
            }

            if (!Helpers.isStringEmpty(evolucion.texto_evolucion)) {
              this.texto_evolucion = evolucion.texto_evolucion;
            }

            if (!Helpers.isStringEmpty(evolucion.impresiones_estado_general)) {
              this.impresiones_estado_general =
                evolucion.impresiones_estado_general;
            }
          }
        });
    } else {
      // Creando
      this.buscarAlertas();
      this.cargando = false;
    }
  }
  ngOnInit() {}

  close() {
    const respuesta = { success: this.success };
    this.dialogRef.close(respuesta);
  }

  buscarAlertas() {
    this.service
      .getData(
        this.url +
          "/api/v1/clinica/cli_config_control_detalle_alertas/" +
          this.id_paciente_campania
      )
      .subscribe((data: any[]) => {
        this.alertas = data;
      });
  }

  buscarSintomas() {
    this.service
      .getData(
        this.url +
          '/api/v1/clinica/cli_sintomas_campania?where={"cli_campania":' +
          this.idcampania +
          ',"baja_fecha":null}'
      )
      .subscribe((data: any[]) => {
        this.sintomas = data;
        this.cargando = false;
      });
  }

  // Función donde obtenemos todos los sintomas cargados
  obtenerSintomasCargados() {
    this.service
      .getData(
        this.url +
          '/api/v1/clinica/cli_evolucion_sintomas_campania?where={"cli_evolucion_campania":' +
          this.id_evolucion +
          ',"baja_fecha":null}'
      )
      .subscribe((data: any) => {
        this.sintomas_cargados = data;
        this.arreglo_sintomas = data;
        if (this.sintomas != undefined) {
          for (let i = 0; i < this.sintomas.length; i++) {
            //sintomas_cargados.cli_sintoma_campania.cli_sintoma
            let encontradoCargado = false;
            for (let k = 0; k < this.sintomas_cargados.length; k++) {
              if (!encontradoCargado) {
                if (
                  this.sintomas[i].id ==
                  this.sintomas_cargados[k].cli_sintoma_campania.id
                ) {
                  this.sintomas[i]["checked"] = 1;
                  encontradoCargado = true;
                } else {
                  this.sintomas[i]["checked"] = 0;
                }
              }
            }
          }
        }
        this.cargando = false;
      });
  }

  // Funcion cuando clickea en le checkbox, verificamos si esta agregando o eliminando
  agregarSintoma(id: any, idsintoma: any, term: any) {
    let i = 0;
    let encontrado = false;
    if (this.id_evolucion != undefined) {
      // editando
      for (let i = 0; i < this.arreglo_sintomas.length; i++) {
        if (!encontrado) {
          if (
            this.arreglo_sintomas[i].cli_sintoma_campania != undefined &&
            this.arreglo_sintomas[i].cli_sintoma_campania.cli_sintoma !=
              undefined &&
            this.arreglo_sintomas[i].cli_sintoma_campania.cli_sintoma ==
              idsintoma
          ) {
            encontrado = true;
            this.arreglo_eliminar_sintomas.push({
              id: this.arreglo_sintomas[i].id,
            });
          } else if (
            this.arreglo_sintomas[i].cli_sintoma_campania == undefined &&
            this.arreglo_sintomas[i].id == idsintoma
          ) {
            encontrado = true;
            this.arreglo_sintomas.splice(i, 1); // Elimina, desde la posicion i, 1 objeto
          }
          if (encontrado) {
            this.arreglo_sintomas.splice(i, 1); // Elimina, desde la posicion i, 1 objeto
          }
        }
      }

      for (let i = 0; i < this.arreglo_agregar_sintomas.length; i++) {
        if (this.arreglo_agregar_sintomas[i].id == id) {
          encontrado = true;
        }
      }

      // Si no lo encontró lo cargamos
      if (!encontrado) {
        for (let k = 0; k < this.arreglo_agregar_sintomas.length; k++) {
          if (id == this.arreglo_agregar_sintomas[k].id) {
            encontrado = true;
          }
        }
      }

      if (!encontrado) {
        let nuevoSintoma = { id: id, term: term };
        this.arreglo_agregar_sintomas.push(nuevoSintoma);
      } else {
        for (let j = 0; j < this.arreglo_agregar_sintomas.length; j++) {
          if (this.arreglo_agregar_sintomas[j].id == id) {
            this.arreglo_agregar_sintomas.splice(j, 1);
          }
        }
      }
      this.edito_sintomas = 1;
    } else {
      // Si lo encuentra lo eliminamos del arreglo
      while (i < this.arreglo_sintomas.length && !encontrado) {
        if (
          this.arreglo_sintomas[i].cli_sintoma_campania != undefined &&
          this.arreglo_sintomas[i].cli_sintoma_campania.cli_sintoma !=
            undefined &&
          this.arreglo_sintomas[i].cli_sintoma_campania.cli_sintoma == id
        ) {
          let eliminar_sintoma = { id: this.arreglo_sintomas[i].id };
          this.arreglo_sintomas.splice(i, 1); // Elimina, desde la posicion i, 1 objeto
          encontrado = true;
        }
        i++;
      }
      // Si no lo encontró lo cargamos
      if (!encontrado) {
        let nuevoSintoma = { id: id, term: term };
        this.arreglo_sintomas.push(nuevoSintoma);
      }
    }
  }

  /*
    Funcion que trae los diagnosticos. Como este componente puede llegar a buscar varios diagnosticos, le enviamos un atributo
    1 para antecedentes
  */
  traerDiagnosticos(tipoDiagnostico: any) {
    if (tipoDiagnostico == 1) {
      // antecedentes
      if (
        this.texto_tesauro_antecedente != undefined &&
        this.texto_tesauro_antecedente.length > 2
      ) {
        //aca tengo que llamar al ws del italiano
        this.service
          .getData(this.urlDiagnosticos + this.texto_tesauro_antecedente)
          .subscribe((data: any) => {
            this.antecedentes = data;
          });
      } else {
        this.antecedentes = [];
      }
    }
  }

  /*
  Funcion para cargar los antecedentes en el arreglo
  1 para antecedentes
  */
  cargarDiagnostico(
    texto: any,
    concept_id: any,
    description_id: any,
    tipoDiagnostico: any
  ) {
    if (tipoDiagnostico == 1) {
      // antecedentes
      let encontrado = false;
      for (let i = 0; i < this.arreglo_antecedentes.length; i++) {
        if (
          this.arreglo_antecedentes[i].subsetid &&
          this.arreglo_antecedentes[i].subsetid == concept_id
        ) {
          encontrado = true;
        }
      }
      if (!encontrado) {
        let nuevoDiagnostico = {
          texto_tesauro: texto,
          subsetid: concept_id,
          descriptionid: description_id,
        };
        this.arreglo_antecedentes.push(nuevoDiagnostico);
      }
      this.antecedentes = [];
      this.texto_tesauro_antecedente = null;

      if (this.id_evolucion != undefined) {
        // Significa que está editando una evolución
        for (let i = 0; i < this.arreglo_agregarAntecedentes.length; i++) {
          if (
            this.arreglo_agregarAntecedentes[i].subsetid &&
            this.arreglo_agregarAntecedentes[i].subsetid == concept_id
          ) {
            encontrado = true;
          }
        }

        if (!encontrado) {
          let nuevoAntecedenteEditado = {
            texto_tesauro: texto,
            subsetid: concept_id,
            descriptionid: description_id,
          };
          this.arreglo_agregarAntecedentes.push(nuevoAntecedenteEditado);
        }
        this.antecedentes = [];
        this.texto_tesauro_antecedente = null;
      }
    }
  }

  /*
  Funcion para eliminar un sintoma del arreglo, recibe por parametro el subsetid
  Tipo lista define de que lista hay que eliminar el elemento
  // Id del diagnostico por si ya viene cargado de la base (esta editando evolucion)
  */
  eliminarItem(subsetid: any, tipoLista: any, id: any) {
    if (tipoLista == 1) {
      // Lista de antecedentes
      for (let i = 0; i < this.arreglo_antecedentes.length; i++) {
        if (this.arreglo_antecedentes[i]["subsetid"] == subsetid) {
          this.arreglo_antecedentes.splice(i, 1); // Elimina, desde la posicion i, 1 objeto
          if (this.id_evolucion) {
            // Significa que estamos editando
            let antecedente = { subsetid: subsetid, id: id };
            this.arreglo_eliminarAntecedentes.push(antecedente);
          }
        }
      }
      for (let i = 0; i < this.arreglo_agregarAntecedentes.length; i++) {
        if (this.arreglo_agregarAntecedentes[i]["subsetid"] == subsetid) {
          this.arreglo_agregarAntecedentes.splice(i, 1);
        }
      }
    }
  }

  buscarEvolucionPrevia() {
    this.service
      .getData(
        this.url +
          '/api/v1/clinica/cli_evoluciones_campania?where={"cli_paciente_campania":' +
          this.id_paciente_campania +
          ',"baja_fecha":null}'
      )
      .subscribe((data: any) => {
        if (data && data.length > 0) {
          this.firstEvolucion = 0;
        } else {
          this.firstEvolucion = 1;
        }
      });
  }
  // Distintas notificaciones al administrador de campañas
  // 1 para notificacion de retirar kit
  // 2 para notificacion de mandar a hisopar
  async notificarAdministrador(tipo: any, fechaEvolucion: any) {
    let tipo_mail;
    let datosPush;
    let title;
    let body;
    let data;
    let to;

    await this.service
      .getData(
        this.url + "/api/v1/search_token_by_nombre_intranet/fvinuela@cmic"
      )
      .subscribe((data: any) => {
        if (data && data.success) {
          to = data.token;
        } else {
          to = null;
        }
      });
    switch (tipo) {
      case 1: // retirar kit
        tipo_mail = 6;
        title = "Retirar un kit - GRUPO CEMICO";
        body = "Retirar un kit - GRUPO CEMICO";
        data = "Retirar un kit - GRUPO CEMICO";
        break;
      case 2:
        tipo_mail = 7;
        title = "Realizar un hisopado - GRUPO CEMICO";
        body = "Realizar un hisopado - GRUPO CEMICO";
        data = "Realizar un hisopado - GRUPO CEMICO";
        break;
      case 3:
        tipo_mail = 8;
        title = "Dar de alta - GRUPO CEMICO";
        body = "Dar de alta - GRUPO CEMICO";
        data = "Dar de alta - GRUPO CEMICO";
        break;
      default:
        break;
    }
    if (tipo_mail != null) {
      let datosMail = {
        tipo_mail: tipo_mail,
        mail: "Federico.vinuela@gmail.com",
        nombreAdministrador: "Federico Viñuela",
        nombreProfesional: this.nombre_profesional,
        nombrePaciente: this.nombre_paciente,
        fecha: fechaEvolucion,
        motivo_alta: this.motivo_alta,
      };
      this.enviarMail(datosMail);
    }

    if (to !== null) {
      datosPush = {
        to: to,
        title: title,
        body: body,
        data: data,
      };
      this.service
        .postData(this.url + "/api/v1/notificacion_push", datosPush)
        .subscribe((mail_enviado: any) => {});
    }
  }

  async enviarMail(datosMail) {
    this.service
      .postData(
        "https://apps.grupocemico.com.ar/teleconsulta/enviar-mail/enviar-mail.php",
        datosMail
      )
      .subscribe((mail_enviado: any) => {
        return mail_enviado;
      });
  }

  async cargarEvolucion() {
    let date = new Date();
    let min =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let horas = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();

    let dia =
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate();
    let mes =
      new Date().getMonth() < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    let anio = new Date().getFullYear();
    let fecha = anio + "-" + mes + "-" + dia;
    let hora =
      new Date().getHours() < 10
        ? "0" + new Date().getHours()
        : new Date().getHours();
    let minutos =
      new Date().getMinutes() < 10
        ? "0" + new Date().getMinutes()
        : new Date().getMinutes();
    let segundos =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    // Agregamos un 0 al principio si el dia es de 1 a 9 para que no rompa el formato de la fecha
    let fechaEvolucion =
      dia.toString() +
      "/" +
      mes.toString() +
      "/" +
      anio +
      " " +
      hora +
      ":" +
      minutos +
      ":" +
      segundos;
    let validadoGeneral = false;
    let validoViveSolo = false;
    let completoViveSolo = false;
    let validadoAlta = false;
    if (this.vive_solo == 0) {
      if (
        this.cumplen_aislamiento_convivientes != "" &&
        this.cumplen_aislamiento_convivientes != undefined &&
        this.cumplen_aislamiento_convivientes != null
      ) {
        validoViveSolo = true;
        completoViveSolo = true;
      } else {
        this.cumplen_aislamiento_convivientes_input.focus();
        validoViveSolo = false;
        await Swal.fire({
          icon: "warning",
          text: "Debe ingresar si cumplen aislamiento los convivientes",
        });
      }
    } else {
      this.cumplen_aislamiento_convivientes = undefined;
      validoViveSolo = true;
    }

    if (this.dar_alta == 1) {
      if (!Helpers.isStringEmpty(this.motivo_alta)) {
        validadoAlta = true;
      } else {
        this.motivo_alta_input.nativeElement.focus();
        validadoAlta = false;
        await Swal.fire({
          icon: "warning",
          text: "Debe ingresar el motivo de alta",
        });
      }
    } else {
      validadoAlta = true;
      this.dar_alta = 0;
    }

    if (validoViveSolo && validadoAlta) {
      if (
        completoViveSolo ||
        (this.texto_evolucion != undefined &&
          this.texto_evolucion != null &&
          this.texto_evolucion.length > 0) ||
        (this.arreglo_agregarAntecedentes &&
          this.arreglo_agregarAntecedentes.length > 0) ||
        (this.impresiones_estado_general != undefined &&
          this.impresiones_estado_general != null &&
          this.impresiones_estado_general.length > 0) ||
        this.dificultad_habla != undefined ||
        this.coloracion_cara_labios != undefined ||
        this.respiracion != undefined ||
        this.disminucion_orina != undefined ||
        this.tiene_termometro != undefined ||
        this.cumple_aislamiento != undefined ||
        this.vive_solo != undefined ||
        this.red_apoyo != undefined ||
        this.ficha_informativa_seguimiento_ambulatorio != undefined ||
        this.retirar_kit != undefined ||
        this.mandar_a_hisopar != undefined ||
        this.dar_alta != undefined
      ) {
        validadoGeneral = true;
      } else {
        await Swal.fire({
          icon: "warning",
          text: "Debe completar al menos un campo para guardar el formulario.",
        });
      }
    }
    if (validadoGeneral) {
      let datosEvolucion = {
        texto_evolucion: this.texto_evolucion,
        impresiones_estado_general: this.impresiones_estado_general,
        id_profesional: this.id_profesional,
        cli_paciente_campania: this.id_paciente_campania,
        dificultad_habla: this.dificultad_habla,
        coloracion_cara_labios: this.coloracion_cara_labios,
        respiracion: this.respiracion,
        disminucion_orina: this.disminucion_orina,
        tiene_termometro: this.tiene_termometro,
        cumple_aislamiento: this.cumple_aislamiento,
        vive_solo: this.vive_solo,
        cumplen_aislamiento_convivientes: this.cumplen_aislamiento_convivientes,
        red_apoyo: this.red_apoyo,
        retirar_kit: this.retirar_kit,
        mandar_a_hisopar: this.mandar_a_hisopar,
        ficha_informativa_seguimiento_ambulatorio: this
          .ficha_informativa_seguimiento_ambulatorio,
      };

      if (this.id_evolucion != undefined) {
        // Significa que está editando el archivo
        this.service
          .putData(
            this.url + "/api/v1/clinica/cli_evolucion_campania",
            this.id_evolucion,
            datosEvolucion
          )
          .subscribe((data: any) => {
            if (
              this.arreglo_agregarAntecedentes &&
              this.arreglo_agregarAntecedentes.length > 0
            ) {
              // Significa que va a agregar antecedentes
              let options = {
                idprofesional: this.id_profesional,
                fecha: fecha,
                hora: hora,
                arreglo_agregarAntecedentes: this.arreglo_agregarAntecedentes,
                id_tipo: this.id_evolucion,
                cli_paciente_campania: this.id_paciente_campania,
                tipo: "Evolucion",
              };
              this.service
                .postData(
                  this.url + "/api/v1/enfermeria/new_enf_antecedentes",
                  options
                )
                .subscribe((data: any) => {});
            }

            if (
              this.arreglo_eliminarAntecedentes &&
              this.arreglo_eliminarAntecedentes.length > 0
            ) {
              // Significa que va a eliminar antecedentes
              let options = {
                baja_fecha: fecha + " " + hora,
              };

              for (
                let i = 0;
                i < this.arreglo_eliminarAntecedentes.length;
                i++
              ) {
                if (this.arreglo_eliminarAntecedentes[i].id != undefined) {
                  this.service
                    .putData(
                      this.url + "/api/v1/enfermeria/update_enf_antecedente",
                      this.arreglo_eliminarAntecedentes[i].id,
                      options
                    )
                    .subscribe((data: any) => {});
                }
              }
            }

            if (this.edito_sintomas == 1) {
              if (
                this.arreglo_eliminar_sintomas != undefined &&
                this.arreglo_eliminar_sintomas.length > 0
              ) {
                // Significa que va a eliminar sintomas
                let datosEliminarSintoma = {
                  baja_fecha: fecha + " " + hora,
                };
                for (
                  let j = 0;
                  j < this.arreglo_eliminar_sintomas.length;
                  j++
                ) {
                  if (this.arreglo_eliminar_sintomas[j].id != undefined) {
                    this.service
                      .putData(
                        this.url +
                          "/api/v1/clinica/cli_evolucion_sintoma_campania",
                        this.arreglo_eliminar_sintomas[j].id,
                        datosEliminarSintoma
                      )
                      .subscribe((data: any) => {});
                  }
                }
              }

              if (
                this.arreglo_agregar_sintomas != undefined &&
                this.arreglo_agregar_sintomas.length > 0
              ) {
                let datosEvolucionSintoma = {
                  cli_evolucion_campania: this.id_evolucion,
                  arreglo_sintomas: this.arreglo_agregar_sintomas,
                };
                this.service
                  .postData(
                    this.url + "/api/v1/clinica/new_sintomas_campania",
                    datosEvolucionSintoma
                  )
                  .subscribe((data: any) => {});
              }
            }
          });

        await Swal.fire({
          icon: "success",
          text: "Se ha modificado la evolución correctamente",
        });
        if (this.retirar_kit == 1) {
          await this.notificarAdministrador(1, fechaEvolucion);
        }
        if (this.mandar_a_hisopar == 1) {
          await this.notificarAdministrador(2, fechaEvolucion);
        }
        if (this.dar_alta == 1) {
          const datosAlta = {
            fecha_alta: Helpers.getDateTimeFullBD(),
            motivo_alta: this.motivo_alta,
          };
          this.service
            .putData(
              this.url + "/api/v1/clinica/cli_paciente_campania",
              this.id_paciente_campania,
              datosAlta
            )
            .subscribe((data: any) => {});

          this.actualizarEpisodioHome();
          await this.notificarAdministrador(3, fechaEvolucion);
        }
        this.success = true;
        this.close();
      } else {
        // Creando el archivo
        this.service
          .postData(
            this.url + "/api/v1/clinica/new_cli_evolucion_campania",
            datosEvolucion
          )
          .subscribe(async (data: any) => {
            this.id_evolucion = data.id;
            if (
              this.arreglo_antecedentes &&
              this.arreglo_antecedentes.length > 0
            ) {
              let options = {
                idprofesional: this.id_profesional,
                fecha: fecha,
                hora: hora,
                arreglo_antecedentes: this.arreglo_antecedentes,
                id_tipo: this.id_evolucion,
                cli_paciente_campania: this.id_paciente_campania,
                tipo: "Evolucion",
              };

              this.service
                .postData(
                  this.url + "/api/v1/enfermeria/new_enf_antecedentes",
                  options
                )
                .subscribe((data: any) => {});
            }

            if (this.alertas != undefined && this.alertas.length > 0) {
              for (let i = 0; i < this.alertas.length; i++) {
                let options = {
                  id_evolucion: this.id_evolucion,
                };
                this.service
                  .putData(
                    this.url + "/api/v1/clinica/cli_control_resultado",
                    this.alertas[i].id,
                    options
                  )
                  .subscribe((data: any) => {});
              }
            }

            if (
              this.arreglo_sintomas != undefined &&
              this.arreglo_sintomas.length > 0
            ) {
              let datosEvolucionSintoma = {
                cli_evolucion_campania: this.id_evolucion,
                arreglo_sintomas: this.arreglo_sintomas,
              };
              this.service
                .postData(
                  this.url + "/api/v1/clinica/new_sintomas_campania",
                  datosEvolucionSintoma
                )
                .subscribe((data: any) => {});
            }
            await Swal.fire({
              icon: "success",
              text: "Se ha creado la evolución correctamente",
            });
            if (this.retirar_kit == 1) {
              await this.notificarAdministrador(1, fechaEvolucion);
            }
            if (this.mandar_a_hisopar == 1) {
              await this.notificarAdministrador(2, fechaEvolucion);
            }
            if (this.dar_alta == 1) {
              const datosAlta = {
                fecha_alta: Helpers.getDateTimeFullBD(),
                motivo_alta: this.motivo_alta,
              };
              this.service
                .putData(
                  this.url + "/api/v1/clinica/cli_paciente_campania",
                  this.id_paciente_campania,
                  datosAlta
                )
                .subscribe((data: any) => {});
                            this.actualizarEpisodioHome();

              await this.notificarAdministrador(3, fechaEvolucion);
            }
            this.success = true;
            this.close();
          });
      }
    }
  }

  async actualizarEpisodioHome() {
    await this.service
      .getData(
        this.urlHome +
          '/api/v1/clinica/cli_episodios?where={"tipo_episodio":3,"paciente_campania":' +
          this.id_paciente_campania +
          ',"or":[{"fecha_egreso":"0000-00-00"},{"fecha_egreso":null}]}'
      )
      .subscribe(async (data: any) => {
        if (data && data.length > 0) {
          // Significa que tiene un episodio
          const id_episodio = data[0].id;
          const datos_alta = {
            tipo_alta: this.motivo_alta,
            fecha_egreso: Helpers.getDateBD(),
            hora_alta: Helpers.getTimeFull(),
            alta_medica: 1,
          };
          this.service
            .putData(
              this.urlHome + "/api/v1/clinica/cli_episodio",
              id_episodio,
              datos_alta
            )
            .subscribe((data: any) => {});
        }
      });
  }
}
