import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { environment } from 'src/environments/environment';
import { TextMaskModule } from 'angular2-text-mask';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ActivatedRoute } from '@angular/router';
import Swal from "sweetalert2";
@Component({
  selector: "app-agregar-control",
  templateUrl: "./agregar-control.component.html",
  styleUrls: ["./agregar-control.component.scss"],
})
export class AgregarControlComponent implements OnInit {
  frecuencia: any;
  minimo_aceptable: any;
  maximo_aceptable: any;
  observaciones: any;
  id_control: number;
  url: string;
  id_paciente_campania: number;
  controles_disponibles: any;
  id_profesional: any;
  tipo_control: string;
  mostrarValorMinimo: number;
  mostrarValorMaximo: number;
  mostrarResultadoSinAlerta: number;
  resultado_sin_alerta: any;
  @ViewChild("frecuencia_input", { static: false }) frecuencia_input: MatSelect;
  @ViewChild("control_input", { static: false }) control_input: MatSelect;
  @ViewChild("resultado_sin_alerta_input", { static: false })
  resultado_sin_alerta_input: MatSelect;
  @ViewChild("minimo_aceptable_input", { static: false })
  minimo_aceptable_input: ElementRef;
  @ViewChild("maximo_aceptable_input", { static: false })
  maximo_aceptable_input: ElementRef;

  constructor(
    activateRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AgregarControlComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private credentialsService: CredentialsService,
    protected service: ApiService
  ) {
    this.url = environment.apiPortalSaludEndpoint;
    this.id_paciente_campania = dataDialog.id_paciente_campania;

    let datosBuscar = {
      id_paciente_campania: this.id_paciente_campania,
      id_campania: this.credentialsService.credentials.campania,
    };
    this.service
      .postData(
        this.url +
          "/api/v1/clinica/cli_config_control_detalle_controles_disponibles",
        datosBuscar
      )
      .subscribe((data: any[]) => {
        this.controles_disponibles = data;
      });
    this.id_profesional = this.credentialsService.credentials.id_profesional_federado
  }
  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
  // Vamos a recibir el tipo
  mostrarDetalle(tipo: any) {
    this.tipo_control = tipo;
    if (tipo == "input") {
      this.mostrarValorMinimo = 1;
      this.mostrarValorMaximo = 1;
    } else {
      this.mostrarValorMinimo = 0;
      this.mostrarValorMaximo = 0;
      if (tipo == "radio" || tipo == "checkbox") {
        this.mostrarResultadoSinAlerta = 1;
      } else {
        this.mostrarResultadoSinAlerta = 0;
      }
    }
  }

  async cargarControl() {
    let fecha =
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate();
    let hora =
      new Date().getHours() +
      ":" +
      new Date().getMinutes() +
      ":" +
      new Date().getSeconds();

    let validadoGeneral = false;
    let completoMinimoAceptable = false;
    let completoMaximoAceptable = false;
    let completoFrecuencia = false;
    let completoControl = false;
    let completoMinimoRadio = false;
    let completoMaximoRadio = false;
    let completoResultadoSinAlerta = false;
    if (this.id_control != undefined && this.id_control != null) {
      completoControl = true;
    } else {
      completoControl = false;
      this.control_input.focus();
      await Swal.fire({
        icon: "warning",
        text: "Debe seleccionar el control",
      });
    }

    if (this.frecuencia != undefined && this.frecuencia != null) {
      completoFrecuencia = true;
    } else {
      completoFrecuencia = false;
      this.frecuencia_input.focus();
      await Swal.fire({
        icon: "warning",
        text: "Debe seleccionar la frecuencia",
      });
    }

    if (this.tipo_control == "input") {
      if (this.minimo_aceptable != undefined && this.minimo_aceptable != null) {
        completoMinimoAceptable = true;
      } else {
        completoMinimoAceptable = false;
        this.minimo_aceptable_input.nativeElement.focus();
        await Swal.fire({
          icon: "warning",
          text: "Debe completar el mínimo aceptable",
        });
      }

      if (this.maximo_aceptable != undefined && this.maximo_aceptable != null) {
        completoMaximoAceptable = true;
      } else {
        completoMaximoAceptable = false;
        this.maximo_aceptable_input.nativeElement.focus();
        await Swal.fire({
          icon: "warning",
          text: "Debe completar el máximo aceptable",
        });
      }

      if (
        completoFrecuencia &&
        completoMaximoAceptable &&
        completoMinimoAceptable &&
        completoControl
      ) {
        validadoGeneral = true;
      }
    } else {
      if (this.tipo_control == "radio" || this.tipo_control == "checkbox") {
        if (
          this.resultado_sin_alerta != undefined &&
          this.resultado_sin_alerta != ""
        ) {
          completoResultadoSinAlerta = true;
        } else {
          completoResultadoSinAlerta = false;
          this.resultado_sin_alerta_input.focus();
          await Swal.fire({
            icon: "warning",
            text: "Debe completar el resultado sin alerta",
          });
        }
      } else {
        completoResultadoSinAlerta = true;
      }

      if (completoFrecuencia && completoResultadoSinAlerta) {
        validadoGeneral = true;
      } else {
        validadoGeneral = false;
      }
    }

    if (validadoGeneral) {
      let datosConfigControlDetalle = {
        cli_control_campania: this.id_control,
        cli_paciente_campania: this.dataDialog.id_paciente_campania,
        "id_profesional":this.id_profesional,
        frecuencia: this.frecuencia,
        minimo_aceptable: this.minimo_aceptable,
        maximo_aceptable: this.maximo_aceptable,
        resultado_sin_alerta: this.resultado_sin_alerta,
      };

      this.service
        .postData(
          this.url + "/api/v1/clinica/new_cli_config_control_detalle",
          datosConfigControlDetalle
        )
        .subscribe(async (data: any) => {
          await Swal.fire({
            icon: "success",
            text: "Se ha agregado el nuevo control",
          });
          this.close();
        });
    }
  }
}
