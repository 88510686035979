import { Component, OnInit } from "@angular/core";
import { ApiService } from "../core/api.service";
import {
  Credentials,
  CredentialsService,
} from "../core/authentication/credentials.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import { CargarEvolucionesComponent } from "../cargar-evoluciones/cargar-evoluciones.component";
import { VerEvolucionesComponent } from "../ver-evoluciones/ver-evoluciones.component";
import { Helpers } from "src/app/helpers.service";

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.scss"],
})
export class InicioComponent implements OnInit {
  nombre: string;
  id_campania: any;
  url: any;
  arreglo_pacientes_campania: any;
  id_paciente_campania: any;
  id_profesional: any;
  cargandoPacientes = true;
  searchText: any;
  public Helpers = Helpers;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    activateRoute: ActivatedRoute,
    protected service: ApiService,
    private credentialsService: CredentialsService
  ) {
    this.url = environment.apiPortalSaludEndpoint;
    if (
      this.credentialsService.credentials == undefined ||
      this.credentialsService.credentials == null
    ) {
      //Si no esta logeado lo mandamos al login
      this.router.navigate(["login"]);
    } else {
      // Si esta logeado verificamos si cambio la pass

      if (
        this.credentialsService.credentials != null &&
        this.credentialsService.credentials != undefined &&
        this.credentialsService.credentials.cambio_password == 1
      ) {
        this.nombre = credentialsService.credentials.nombre;
        this.id_profesional =
          credentialsService.credentials.id_profesional_federado;
        // Seteamos el nombre y la campania a la que pertenece el profesional
        this.id_campania = credentialsService.credentials.campania;
        this.buscarPacientes();
      } else {
        // Si no cambio la pass lo mandamos a que lo haga
        this.router.navigate(["nuevo-pass"]);
      }
    }
  }

  ngOnInit() {}

  // Funcion para abrir componente para agregar una evolucion
  abrirCargarEvolucion(id_paciente_campania, nombre_paciente) {
    this.id_paciente_campania = id_paciente_campania;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: "Agregar control",
      id_paciente_campania: id_paciente_campania,
      nombre_paciente: nombre_paciente,
    };

    const dialogRef = this.dialog.open(
      CargarEvolucionesComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.buscarPacientes();
      }
    });
  }

  // Funcion para abrir componente para agregar una evolucion
  verEvoluciones(id_paciente_campania) {
    this.id_paciente_campania = id_paciente_campania;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: "Agregar control",
      id_paciente_campania: this.id_paciente_campania,
    };

    const dialogRef = this.dialog.open(VerEvolucionesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  async generarTeleconsulta(
    id_paciente_campania,
    id_paciente: any,
    token_push: any
  ) {
    let date = new Date();
    let fecha =
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate();
    let hora =
      new Date().getHours() +
      ":" +
      new Date().getMinutes() +
      ":" +
      new Date().getSeconds();
    let token = Math.floor(Math.random() * 1000000000);
    let datosTeleconsulta = {
      hora_atencion: hora,
      estado: 1,
      token: token,
      idprofesional: this.id_profesional,
      cli_paciente: id_paciente,
      fecha: fecha,
      hora: hora,
      fecha_inicio: fecha + " " + hora,
      token_push: token_push,
      estado_consentimiento: 2,
    };

    this.service
      .postData(
        this.url + "/api/v1/clinica/new_teleconsulta",
        datosTeleconsulta
      )
      .subscribe((teleconsulta_creada: any) => {
        if (teleconsulta_creada != undefined && teleconsulta_creada != []) {
          // Si se crea la teleconsulta
          let datosTeleconsultaCampania = {
            cli_paciente_campania: id_paciente_campania,
            id_teleconsulta: teleconsulta_creada.id,
            es_inicial: 0,
          };
          this.service
            .postData(
              this.url + "/api/v1/clinica/new_cli_teleconsulta_campania",
              datosTeleconsultaCampania
            )
            .subscribe(async (teleconsulta_campania_creada: any) => {
              if (
                teleconsulta_campania_creada != undefined &&
                teleconsulta_campania_creada != []
              ) {
                let link =
                  "https://apps.grupocemico.com.ar/teleconsulta/teleconsulta-paciente/index.php?tk=" +
                  teleconsulta_creada.token +
                  "&av=2";
                let datosMail = {
                  tipo_mail: 1,
                  nombrePaciente: teleconsulta_creada.cli_paciente.nombre,
                  mail: teleconsulta_creada.cli_paciente.mail,
                  nombreProfesional: this.nombre,
                  link: link,
                };
                //this.service.postData('https://apps.grupocemico.com.ar/teleconsulta/pacientes-espera/enviar-mail.php', datosMail).subscribe((mail_enviado: any) => {
                await this.enviarMail(datosMail);

                let av_teleconsulta = 2;
                let datosEnviar = {
                  token_teleconsulta: token_push,
                  av_teleconsulta: av_teleconsulta,
                };

                const datosPush = {
                  notificacion_push: {
                    title: "Teleconsulta CMIC",
                    body: "Su Teleconsulta está lista",
                    usuario_envia: this.credentialsService.credentials.usuario,
                    token_push_envia: this.credentialsService.credentials
                      .token_msj,
                    data: datosEnviar,
                    aplicacion: ["portal-medico"],
                    to_usuarios: [token_push],
                  },
                };

                this.service
                  .postData(
                    this.url + "/api/v1/enviar_notificaciones",
                    datosPush
                  )
                  .subscribe((notificacion_enviada: any) => {});
                this.router.navigate([
                  "/teleconsulta-chat/" +
                    token +
                    "/" +
                    av_teleconsulta +
                    "/" +
                    teleconsulta_creada.id +
                    "/" +
                    id_paciente +
                    "/" +
                    this.id_profesional,
                ]);
              }
            });
        }
      });
  }

  async buscarPacientes() {
    this.cargandoPacientes = true;
    this.service
      .getData(
        this.url +
          "/api/v1/clinica/get_pacientes_inicio/" +
          this.id_campania +
          "/" +
          this.id_profesional
      )
      .subscribe((data: any) => {
        if (data && data.success) {
          this.arreglo_pacientes_campania = data.data;
        }
        this.cargandoPacientes = false;
      });
  }

  async enviarMail(datosMail) {
    this.service
      .postData(
        "https://apps.grupocemico.com.ar/teleconsulta/enviar-mail/enviar-mail.php",
        datosMail
      )
      .subscribe((mail_enviado: any) => {
        if (mail_enviado) {
        } else {
        }
      });
  }
}
