import { Component, OnInit } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-seleccion-campania',
  templateUrl: './seleccion-campania.component.html',
  styleUrls: ['./seleccion-campania.component.scss']
})
export class SeleccionCampaniaComponent implements OnInit {

  url:any;
  arreglo_campanias: Array<any> = [];

  constructor(private credentialsService: CredentialsService, protected service: ApiService, private router: Router) {
    this.url = environment.apiPortalSaludEndpoint;
    this.service.getData(this.url + '/api/v1/clinica/cli_campanias?where={"baja_fecha":null}').subscribe((campanias: any[]) => {
      this.arreglo_campanias = campanias;
    })
    
  }
  // Va a recibir por parametro el id sector que seleccionó y lo va a setear en las credenciales
  seleccionarCampania(id:any){
    this.credentialsService.setCampania(id);
    this.router.navigateByUrl('inicio').then((e) => {
      if (e) {
      } else {
      }
    });
  }

  ngOnInit() {
  }

}
