import { Component, OnInit } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import { CargarEvolucionesComponent } from "../cargar-evoluciones/cargar-evoluciones.component";
import { VerEvolucionesComponent } from '../ver-evoluciones/ver-evoluciones.component';
import { Helpers } from "src/app/helpers.service";
import {AppComponent} from "../app.component";

@Component({
  selector: "app-notificaciones",
  templateUrl: "./notificaciones.component.html",
  styleUrls: ["./notificaciones.component.scss"],
})
export class NotificacionesComponent implements OnInit {
  nombre: string;
  idcampania: any;
  url: any;
  id_profesional: any;
  cargando = true;
  notificaciones: any;
  public Helpers = Helpers;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    activateRoute: ActivatedRoute,
    protected service: ApiService,
    private credentialsService: CredentialsService,
    public MyApp: AppComponent
  ) {
    this.url = environment.apiPortalSaludEndpoint;
    this.nombre = credentialsService.credentials.nombre;
    this.id_profesional =
      credentialsService.credentials.id_profesional_federado;
    // Seteamos el nombre y la campania a la que pertenece el profesional
    this.idcampania = credentialsService.credentials.campania;
    this.buscarNotificaciones();
  }

  ngOnInit() {}

  async buscarNotificaciones() {
    await this.service
      .getData(
        this.url +
          "/api/v1/get_notificaciones/" +
          this.credentialsService.credentials.usuario + "/" + this.credentialsService.credentials.token_msj
      )
      .subscribe(async (notificaciones: any) => {
        if (notificaciones && notificaciones.success) {
          this.notificaciones = notificaciones.data;
              this.credentialsService.setCantidadNotificaciones(
                0
              );
          this.MyApp.actualizarNotificacionRecibida(null);
        }
      });
      this.cargando = false;
  }
}
