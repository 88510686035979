import * as moment from "moment";

export const Helpers = {
  getDateTimeFullBD() {
    // Retorna fecha para la bd con formato 2020-06-25 08:00:00
    return moment().format("YYYY-MM-DD HH:mm:ss");
  },
  getDateTimeForUsuario() {
    // Retorna fecha completa visible para el usuario
    return moment().format("DD-MM-YYYY HH:mm:ss");
  },
  getDateBD() {
    // Retorna fecha para la bd con formato 2020-06-25
    return moment().format("YYYY-MM-DD");
  },
  getHourAndMinute() {
    // Retorna solo hora y fecha. Formato 08:00
    return moment().format("HH:mm");
  },
  getTimeFull() {
    // Retorna hora completa. Formato: 08:00:00
    return moment().format("HH:mm:ss");
  },
  getHours() {
    // Retorna solo la hora. Formato: 06
    return moment().format("HH");
  },
  parseNumberToString(param: any) {
    // Si es numero, lo devuelve convertido a string, si no devuelve una cadena vacia
    return typeof param === "number" ? param.toString() : null;
  },
  isStringEmpty: (param: any) =>
    param && param.replace(/\s/g, "").length > 0 ? false : true,
  hasMoreTwoLetters: (param: any) => (param && param.length > 2 ? true : false),
  parseNumberToSiOrNo: (param: any) => (param === 1 ? "Si" : "No"),
  calcularIMC(peso: number, talla: number) {
    return peso / (talla * talla);
  },
  parseEstadoDeConciencia(param: any) {
    let respuesta;
    switch (parseInt(param)) {
      case 1:
        respuesta = "Lúcido";
        break;
      case 2:
        respuesta = "Orientado";
        break;
      case 3:
        respuesta = "Desorientado en tiempo y espacio";
        break;
      case 4:
        respuesta = "Coma farmacológico";
        break;
      case 5:
        respuesta = "Coma";
        break;
      default:
        break;
    }
    return respuesta;
  },
  parseDateTimePickerToDateTimeBD(fecha_utc: string) {
    let fecha;
    if (fecha_utc) {
      let fecha_formato_utc = new Date(fecha_utc);
      fecha = JSON.stringify(fecha_formato_utc);
      fecha = fecha.slice(1, 11) + " " + fecha.slice(12, 20);
    }
    return fecha;
  },
};
