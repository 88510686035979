import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { environment } from 'src/environments/environment';
import { TextMaskModule } from 'angular2-text-mask';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-eliminar-control',
  templateUrl: './eliminar-control.component.html',
  styleUrls: ['./eliminar-control.component.scss']
})
export class EliminarControlComponent implements OnInit {
  url: any;
  motivo_baja:any;
  @ViewChild('#motivo_baja_input', { static: false }) motivo_baja_input: ElementRef;

  constructor(activateRoute: ActivatedRoute, private dialog: MatDialog, private dialogRef: MatDialogRef<EliminarControlComponent>, @Inject(MAT_DIALOG_DATA) public dataDialog: any, private credentialsService: CredentialsService, protected service: ApiService) {
    this.url = environment.apiPortalSaludEndpoint;
  }
  ngOnInit() {
  }

  close() {
    this.dialogRef.close()
  }

  async eliminarControl(){
    let date = new Date();
    let fecha = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
    let min = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes();
    let horas = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours();
    let segundos = (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds();
  
    let hora = horas + ':' + min;
    let fechaCompletaActual = fecha + " " + hora + ":" + segundos;

    if (this.motivo_baja != undefined && this.motivo_baja != null && this.motivo_baja.length>0){
      
      let datosEliminarControl = {
        motivo_baja: this.motivo_baja,
        baja_fecha: fechaCompletaActual,
      };

      this.service.putData(this.url + '/api/v1/clinica/cli_config_control_detalle', this.dataDialog.id_config_control_detalle, datosEliminarControl).subscribe(async (data: any) => {
        await Swal.fire({
          icon: "success",
          text: "Se ha eliminado el control al paciente",
        });
        this.close();
      })
      
    } else {
      await Swal.fire({
        icon: "warning",
        text: "Debe ingresar el motivo",
      });
    }
  }


}
