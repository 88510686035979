import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { APP_CONFIG, AppConfig } from '../app-config.module';



@Injectable()
export class ApiService {
  public url:any;
  data:any
    constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {
        this.url = this.config.apiEndpoint;
    }
    public setDetails(eventData: any) {
      this.data = eventData;
    }
  
    public getDetails() {
      return this.data;
    }
  
  getData (endpoint:any): Observable<any> {
    return this.http.get(endpoint);
  }

  
  postData (endpoint:any,data:any): Observable<any> {
    return this.http.post(endpoint+'/',data);
  }
  
  putData (endpoint:any,id:any,data:any): Observable<any> {
    return this.http.put(endpoint+'/'+id,data);
  }
  
  deleteData (endpoint:any,id:any): Observable<any> {
    return this.http.delete(endpoint+'/'+id);
  }
}
