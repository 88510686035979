import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../core/api.service";
import {
  Credentials,
  CredentialsService,
} from "../core/authentication/credentials.service";
import { environment } from "../../environments/environment";
import Swal from "sweetalert2";
import { DeviceDetectorService } from "ngx-device-detector";
import { Helpers } from "src/app/helpers.service";
import { Plugins } from "@capacitor/core";
const { Device } = Plugins;
import { AppComponent } from "../app.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  version: string | null = environment.version;
  error: boolean = true;
  loginForm!: FormGroup;
  isLoading = false;
  message = "";
  titulo = "Portal Salud Médico";
  grupo = "Grupo CEMICO";
  showHead = false;
  usuario: any;
  public url: any;
  urlIntranet: any;
  id_profesional_federado: any;
  deviceInfo: any;
  capacitorInfo: any;
  isVisible: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    protected service: ApiService,
    private deviceService: DeviceDetectorService,
    public MyApp: AppComponent
  ) {
    this.url = environment.apiPortalSaludEndpoint;
    this.urlIntranet = environment.apiIntranetEndPoint;
    this.controlVersion();

    if (
      this.credentialsService.credentials &&
      this.credentialsService.credentials.id_profesional_federado
    ) {
      this.isVisible = false;
      this.newLog("Ya esta logeado");
      this.searchNotificaciones();
      this.router.navigateByUrl("inicio").then((e) => {});
    } else {
      this.isVisible = true;
      this.createForm();
      this.credentialsService.setCredentials(undefined);
    }
  }

  async searchNotificaciones() {
    let cantidad_notificaciones = 0;
    try {
      await this.service
        .getData(
          this.url +
            "/api/v1/get_notificaciones/" +
            this.credentialsService.credentials.usuario +
            "/" +
            this.credentialsService.credentials.token_msj
        )
        .subscribe(async (notificaciones: any) => {
          if (notificaciones && notificaciones.success) {
            for (let i = 0; i < notificaciones.data.length; i++) {
              if (!notificaciones.data[i].fecha_recibida) {
                cantidad_notificaciones++;
              }
            }
          }
          this.MyApp.setCantidadNotificaciones(cantidad_notificaciones);
        });
    } catch (e) {
      this.MyApp.setCantidadNotificaciones(0);
    }
  }

  async newLog(message: string) {
    await this.getInfoDevice();
    const datos_log = {
      appVersion: this.capacitorInfo.appVersion
        ? this.capacitorInfo.appVersion
        : environment.APP_VERSION,
      appBuild: this.capacitorInfo.appBuild
        ? this.capacitorInfo.appBuild
        : environment.APP_VERSION,
      browser: this.deviceInfo.userAgent,
      operatingSystem: this.deviceInfo.os,
      osVersion: this.capacitorInfo.osVersion,
      device: this.deviceInfo.device,
      usuario:
        this.loginForm && this.loginForm.value && this.loginForm.value.usuario
          ? this.loginForm.value.usuario.toLowerCase().replace(/\s/g, "")
          : this.credentialsService.credentials.usuario,
      id_app: 2,
      message: message,
      fecha_acceso: Helpers.getDateTimeFullBD(),
      ip: (<HTMLInputElement>document.getElementById("ip")).value,
      manufacturer: this.capacitorInfo.manufacturer,
      platform: this.capacitorInfo.platform,
      model: this.capacitorInfo.model,
    };
    this.service
      .postData(this.url + "/api/v1/new_log_acceso", datos_log)
      .subscribe((log: any[]) => {});
  }

  async getInfoDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.capacitorInfo = await Device.getInfo();
  }
  ngOnInit() {}

  ngOnDestroy() {}

  login() {
    const option = {
      usuario: this.loginForm.value.usuario.toLowerCase().replace(/\s/g, ""),
      password: this.loginForm.value.password,
    };
    this.service
      .postData(this.urlIntranet + "/api/v1/security/login", option)
      .subscribe((data: any[]) => {
        this.newLog(data["message"]);
        if (data["success"] == true) {
          //data["usuario"][0].usuario = "mmulbayer@cmic";
          this.service
            .getData(
              "https://apps.grupocemico.com.ar/federador/index.php?r=api/profesionales/getByIntranetContact&intranet_name=" +
                data["usuario"][0].usuario
            )
            .subscribe(async (user_federado: any) => {
              if (user_federado && user_federado.id) {
                let credential = {
                  nombre: data["usuario"][0].nombre_completo,
                  usuario: data["usuario"][0].usuario,
                  email: data["usuario"][0].email,
                  id_intranet: data["usuario"][0].id,
                  id_usuario_portal: "",
                  id_profesional_federado: user_federado.id,
                  cambio_password: 1,
                  campania: "",
                  token_msj: data["usuario"][0].token_msj,
                  cantidad_notificaciones: 0,
                };
                this.credentialsService.setCredentials(credential, false);
                await this.searchNotificaciones();
                // Seteamos las credenciales

                // Significa que hizo el login correctamente. Ahora buscamos cuantas campañas hay activas

                this.service
                  .getData(
                    this.url +
                      '/api/v1/clinica/cli_campanias?where={"baja_fecha":null}'
                  )
                  .subscribe(async (campanias: any[]) => {
                    if (campanias.length > 0) {
                      if (campanias.length == 1) {
                        // Hay 1 sola activa la seteamos y redirigimos al login
                        this.credentialsService.setCampania(campanias[0].id);
                        this.router.navigateByUrl("inicio").then((e) => {
                          if (e) {
                            console.log("Navigation is successful!");
                          } else {
                            console.log("Navigation has failed!");
                          }
                        });
                      } else {
                        // Tiene mas de una campania y debe elegir con cual iniciar
                        this.router
                          .navigateByUrl("seleccion-campania")
                          .then((e) => {
                            if (e) {
                              console.log("Navigation is successful!");
                            } else {
                              console.log("Navigation has failed!");
                            }
                          });
                      }
                    } else {
                      console.log("No hay campañas disponibles");
                    }
                  });
              } else {
                await Swal.fire({
                  icon: "error",
                  text:
                    "No se encuentra federado. Por favor acercarse al Departamento de Informática en Sistemas del GRUPO CEMICO para registrar su firma.",
                });
              }
            });
        } else {
          this.error = false;
          this.message = data["message"];
        }
      });
  }
  private createForm() {
    this.loginForm = this.formBuilder.group({
      usuario: ["", Validators.required],
      password: ["", Validators.required],
      //remember: true
    });
  }
  async controlVersion() {
    const info = await Device.getInfo();
    await this.getInfoDevice();
    let infoVersion: any = await this.searchInfoVersion();
    if (
      infoVersion &&
      infoVersion.success &&
      infoVersion.data &&
      infoVersion.data.package &&
      infoVersion.data.package.version
    ) {
      
      let ultimaVersion = infoVersion.data.package.version;
      let versionInstalada = info.appVersion || environment.APP_VERSION;
      let arrayUltimaVersion = ultimaVersion.split(".");
      let arrayVersionInstalada = versionInstalada.split(".");

      let actualizar = false;
      if (
        parseInt(arrayUltimaVersion[0]) > parseInt(arrayVersionInstalada[0])
      ) {
        actualizar = true;
      } else if (
        parseInt(arrayUltimaVersion[0]) ===
          parseInt(arrayVersionInstalada[0]) &&
        parseInt(arrayUltimaVersion[1]) > parseInt(arrayVersionInstalada[1])
      ) {
        actualizar = true;
      }
      let text;
      let confirmButtonText;
      if (actualizar) {
        if (this.capacitorInfo.platform=="web"){
          text = "Existe una nueva versión con mejoras. Por favor recargue la aplicación."
          confirmButtonText = "Actualizar";
        } else {
          text = "Existe una nueva versión con mejoras. ¿Desea descargarla ahora?";
          confirmButtonText = "Descargar";
        }
        let urlDescargaApk = infoVersion.data.package["download-link"];
        await Swal.fire({
          title: "Nueva version del Portal Médico",
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: confirmButtonText,
          cancelButtonText: "Continuar de todas formas",
        }).then(async (result) => {
          if (result.value) {
            if (this.capacitorInfo.platform == "web") {
              window.location.reload();
            } else {
              window.open(urlDescargaApk);
            }
          }
        });
      }
    }
  }

  searchInfoVersion() {
    return new Promise((resolve, reject) => {
      this.service
        .getData(this.url + "/api/v1/get_info/avicenna-portal-medico")
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }
}
