import { Injectable } from '@angular/core';

export interface Credentials {
  // Customize received credentials here
  nombre: string;
  usuario: string;
  email: string;
  id_intranet: number;
  id_usuario_portal: string;
  id_profesional_federado: string;
  cambio_password: number;
  campania: string;
  token_msj: string;
  cantidad_notificaciones: number;
}

const credentialsKey = 'credentials';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  private _credentials: Credentials | null = null;

  constructor() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      // const storage = remember ? localStorage : sessionStorage;
      const storage = localStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      localStorage.removeItem(credentialsKey);
    }
  }

  setCampania(campania: any) {
    this.credentials.campania = campania;
    localStorage.setItem(credentialsKey, JSON.stringify(this.credentials));
  }

  setToken(token_msj: string) {
    this.credentials.token_msj = token_msj;
    localStorage.setItem(credentialsKey, JSON.stringify(this.credentials));
  }
  setCantidadNotificaciones(cantidad_notificaciones: number) {
    this.credentials.cantidad_notificaciones = cantidad_notificaciones;
    localStorage.setItem(credentialsKey, JSON.stringify(this.credentials));
  }

  removeCredential() {
    localStorage.clear();
  }

}
