import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material";
import { CargarEvolucionesComponent } from "../cargar-evoluciones/cargar-evoluciones.component";
import * as moment from 'moment'
import { Helpers } from "src/app/helpers.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-ver-evoluciones",
  templateUrl: "./ver-evoluciones.component.html",
  styleUrls: ["./ver-evoluciones.component.scss"],
})
export class VerEvolucionesComponent implements OnInit {
  arreglo_evoluciones: any;
  evolucion: any;
  contadorEvoluciones: any;
  id_paciente_campania: any;
  url: any;
  evolucion_id: any;
  hora_evolucion: any;
  fecha_evolucion: any;
  nombre_profesional: any;
  limit: any;
  offset: any;
  sintomas: any;
  paciente_campania: any;
  nombre_paciente: any;
  edad_paciente: any;
  sexo_paciente: any;
  puede_editar_evo: any;
  cargandoEvoluciones: any;
  numero_documento: any;
  tipo_documento: any;
  antecedentes: any;
  alertas: any;
  public Helpers = Helpers;

  constructor(
    activateRoute: ActivatedRoute,
    private credentialsService: CredentialsService,
    protected service: ApiService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private dialog: MatDialog
  ) {
    this.id_paciente_campania = dataDialog.id_paciente_campania;
    this.url = environment.apiPortalSaludEndpoint;
    this.cargandoEvoluciones = true;
    this.obtenerPacienteCampania();
    this.contadorEvoluciones = 0;
    this.obtenerEvoluciones();
  }

  obtenerPacienteCampania() {
    this.service
      .getData(
        this.url +
          "/api/v1/clinica/cli_paciente_campania/" +
          this.id_paciente_campania
      )
      .subscribe((data: any) => {
        this.paciente_campania = data;
        this.nombre_paciente = this.paciente_campania.cli_paciente.nombre;
        this.edad_paciente = this.obtenerEdad();
        this.sexo_paciente = this.paciente_campania.cli_paciente.sexo;
        this.numero_documento = this.paciente_campania.cli_paciente.numero_documento;
        this.tipo_documento = this.paciente_campania.cli_paciente.tipo_documento;
      });
  }

  //obtenemos todos las evoluciones
  obtenerEvoluciones() {
    this.service
      .getData(
        this.url +
          "/api/v1/clinica/cli_evoluciones_campania_completo/" +
          this.id_paciente_campania
      )
      .subscribe((data: any) => {
        this.arreglo_evoluciones = data;
        if (this.arreglo_evoluciones.length > 0) {
          this.obtenerEvolucionIndividual();
        } else {
          this.cargandoEvoluciones=false;
        }
      });
      this.obtenerAntecedentes();
  }

  // Obtenemos 1 sola evolucion
  obtenerEvolucionIndividual() {
    this.evolucion = this.arreglo_evoluciones[this.contadorEvoluciones];
    this.evolucion_id = this.evolucion.id;
    let fecha_evolucion = this.evolucion.createdAt;
    this.hora_evolucion = fecha_evolucion.slice(11, 19);
    this.fecha_evolucion =
      fecha_evolucion.slice(8, 10) +
      "-" +
      fecha_evolucion.slice(5, 7) +
      "-" +
      fecha_evolucion.slice(0, 4);
    this.nombre_profesional = this.evolucion.cli_profesional.nombre;
    this.obtenerSintomas();
    this.obtenerAlertas();
    this.cargandoEvoluciones = false;
  }
  // Funcion para buscar evoluciones anteriores.
  obtenerEvolucionesAnteriores() {
    this.limit = this.limit + 20;
    this.offset = this.offset + 20;
    this.obtenerEvoluciones();
  }

  // Funcion para obtener la evolucion siguiente
  siguienteEvolucion() {
    this.cargandoEvoluciones = true;
    this.contadorEvoluciones++;
    this.obtenerEvolucionIndividual();
  }
  // Funcion para obtener la evolucion anterior
  anteriorEvolucion() {
    this.cargandoEvoluciones = true;
    this.contadorEvoluciones--;
    this.obtenerEvolucionIndividual();
  }

  // Función donde obtenemos todos los antecedentes
  obtenerAntecedentes() {
    this.service
      .getData(
        this.url +
          '/api/v1/enfermeria/enf_antecedentes?where={"cli_paciente_campania":' +
          this.id_paciente_campania +
          "}"
      )
      .subscribe((data: any) => {
        this.antecedentes = data;
      });
  }

  // Función donde obtenemos todos los sintomas cargados
  obtenerSintomas() {
    this.service
      .getData(
        this.url +
          "/api/v1/clinica/cli_evolucion_sintomas_campania_completo/" +
          this.evolucion_id
      )
      .subscribe((data: any) => {
        this.sintomas = data;
        this.cargandoEvoluciones = false;
      });
  }

  obtenerAlertas() {
    this.service
      .getData(
        this.url +
          "/api/v1/clinica/search_alertas_evolucion/" +
          this.evolucion_id
      )
      .subscribe((data: any) => {
        this.alertas = data;
      });
  }

  obtenerEdad() {
    let edadPaciente;
    if (
      this.paciente_campania != undefined &&
      this.paciente_campania.cli_paciente.fecha_nacimiento != undefined &&
      this.paciente_campania.cli_paciente.fecha_nacimiento != null &&
      this.paciente_campania.cli_paciente.fecha_nacimiento.trim() != ""
    ) {
      let anio = this.paciente_campania.cli_paciente.fecha_nacimiento.slice(
        0,
        4
      );
      let mes = this.paciente_campania.cli_paciente.fecha_nacimiento.slice(
        5,
        7
      );
      let dia = this.paciente_campania.cli_paciente.fecha_nacimiento.slice(
        8,
        10
      );
      let fechaNacimiento = new Date(anio, mes - 1, dia);
      // Obtenemosla fecha de nacimiento guardada en la base y creamos un obj date
      let fechaActual = new Date();
      // Creamos un obj date con la fecha actual y sacamos la diferencia de año
      let diferenciaAnio =
        fechaActual.getFullYear() - fechaNacimiento.getFullYear();
      if (fechaActual.getMonth() < fechaNacimiento.getMonth()) {
        // Si el mes actual es menor al de nacimiento, le restamos 1 año
        diferenciaAnio--;
      }
      if (
        fechaActual.getMonth() == fechaNacimiento.getMonth() &&
        fechaActual.getDate() < fechaNacimiento.getDate()
      ) {
        // Si estamos en el mismo mes y el dia actual es menor al dia de nacimiento, le restamos 1 año
        diferenciaAnio--;
      }
      edadPaciente = diferenciaAnio;
    } else {
      edadPaciente = "";
    }
    return edadPaciente;
  }

  // funcion donde cargamos el componente de evolución y le enviamos los datos para modificar

  async editarEvolucion(id_evolucion: any) {
    let fechaHoy = moment().format("YYYY-MM-DD HH:mm");
    let fechaEvolucionMoment = moment([
      this.evolucion.createdAt.slice(0, 4),
      this.evolucion.createdAt.slice(5, 7) - 1,
      this.evolucion.createdAt.slice(8, 10),
      this.evolucion.createdAt.slice(11, 13),
      this.evolucion.createdAt.slice(14, 16),
      this.evolucion.createdAt.slice(17, 19),
    ]);
    let diferenciaDias = fechaEvolucionMoment.diff(fechaHoy, "days");
    if (diferenciaDias < 0) {
      // Significa que ya pasó un dia
      this.puede_editar_evo = false;
    } else {
      // No pasó un dia y puede editar la evo
      this.puede_editar_evo = true;
    }

    if (this.puede_editar_evo) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        title: "Editar evolución",
        id_evolucion: id_evolucion,
        id_paciente_campania: this.id_paciente_campania
      };

      const dialogRef = this.dialog.open(
        CargarEvolucionesComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result) => {
        this.cargandoEvoluciones = true;
        this.obtenerEvoluciones();
      });
    } else {
      await Swal.fire({
        icon: "error",
        text: "Ya pasó más de un día y no puede editar la evolución",
      });
    }
  }

  ngOnInit() {}
}
