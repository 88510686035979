import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { InicioComponent } from "./inicio/inicio.component";
import { PerfilComponent } from "./perfil/perfil.component";
import { NuevoPassComponent } from "./nuevo-pass/nuevo-pass.component";
import { SeleccionCampaniaComponent } from "./seleccion-campania/seleccion-campania.component";
import { VerControlesComponent } from "./ver-controles/ver-controles.component";
import { VerEvolucionesComponent } from "./ver-evoluciones/ver-evoluciones.component";
import { ConfigurarControlesComponent } from "./configurar-controles/configurar-controles.component";
import { CargarEvolucionesComponent } from "./cargar-evoluciones/cargar-evoluciones.component";
import { TeleconsultaChatComponent } from "./teleconsulta-chat/teleconsulta-chat.component";
import { TeleconsultasPendientesComponent } from "./teleconsultas-pendientes/teleconsultas-pendientes.component";
import { NotificacionesComponent } from "./notificaciones/notificaciones.component";

const routes: Routes = [
  { path: "inicio", component: InicioComponent },
  { path: "nuevo-pass", component: NuevoPassComponent },
  { path: "ver-controles/:id", component: VerControlesComponent },
  { path: "ver-evoluciones/:id", component: VerEvolucionesComponent },
  { path: "configurar-controles/:id", component: ConfigurarControlesComponent },
  { path: "cargar-evoluciones/:id", component: CargarEvolucionesComponent },
  { path: "perfil", component: PerfilComponent },
  { path: "login", component: LoginComponent },
  {
    path: "teleconsulta-chat/:av/:token_teleconsulta",
    component: TeleconsultaChatComponent,
  },
  { path: "seleccion-campania", component: SeleccionCampaniaComponent },
  {
    path: "teleconsulta-chat/:av/:token_teleconsulta",
    component: TeleconsultaChatComponent,
  },
  {
    path:
      "teleconsulta-chat/:token_teleconsulta/:av/:id_teleconsulta/:id_paciente/:id_profesional",
    component: TeleconsultaChatComponent,
  },
  {
    path: "teleconsultas-pendientes",
    component: TeleconsultasPendientesComponent,
  },
  {
    path: "notificaciones",
    component: NotificacionesComponent,
  },
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "**", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
