import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, Params, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-teleconsulta-chat',
  templateUrl: './teleconsulta-chat.component.html',
  styleUrls: ['./teleconsulta-chat.component.scss']
})

export class TeleconsultaChatComponent implements OnInit {
  token_teleconsulta: string
  nombre_paciente: string
  token_paciente: string
  url: string
  urlSafe: SafeResourceUrl;
  constructor(private router: Router, private rutaActiva: ActivatedRoute, public sanitizer: DomSanitizer) {
    let av = this.rutaActiva.snapshot.params.av;
    let tokenTeleconsulta = this.rutaActiva.snapshot.params.token_teleconsulta;
    let id_paciente = this.rutaActiva.snapshot.params.id_paciente
    let id_profesional = this.rutaActiva.snapshot.params.id_profesional
    let id_teleconsulta = this.rutaActiva.snapshot.params.id_teleconsulta
    this.url = 'https://apps.grupocemico.com.ar/teleconsulta/formulario-profesional/index.php?tk=' + tokenTeleconsulta + '&av=' + av +'&idteleconsulta=' + id_teleconsulta + '&idpaciente=' + id_paciente + '&idprofesional=' + id_profesional;
  }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}