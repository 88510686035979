// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { env } from "./.env";

export const environment = {
  production: false,
  hmr: true,
  version: env.npm_package_version + "-dev",
  serverUrl: "/api",
  defaultLanguage: "en-US",
  APP_VERSION: '1.0.0',

  
  // Localhost

  // apiFunsalEndpoint: "http://localhost:1338",
  // apiPortalSaludEndpoint: "http://localhost:1339",
  // apiCmicEndpoint: "http://localhost:1337",
  // apiHomeEndpoint: "http://localhost:1343",

  // Server prod
  
  apiFunsalEndpoint:
    "https://apps.grupocemico.com.ar/conciencia-backend",
  apiPortalSaludEndpoint:
    "https://apps.grupocemico.com.ar/portal-salud-backend",
  apiCmicEndpoint: "https://apps.grupocemico.com.ar/avicenna-backend",
  apiHomeEndpoint: "https://apps.grupocemico.com.ar/avicenna-backend-home",
   
  // Server prueba
  /* 
        apiPortalSaludEndpoint: 'https://apps.grupocemico.com.ar/portal-salud-backend-prueba',
        apiCmicEndpoint: 'https://apps.grupocemico.com.ar/avicenna-backend-prueba',
        apiFunsalEndpoint: 'https://apps.grupocemico.com.ar/conciencia-backend-prueba',
        apiHomeEndpoint: "https://apps.grupocemico.com.ar/avicenna-backend-home-prueba",

    */

  apiIntranetEndPoint: "https://apps.grupocemico.com.ar/intranet-backend",
  apiAlmaWebPlatform: "https://apps.grupocemico.com.ar/almaweb-platform", //'http://cmic.hiperion.com.ar:1338',//'http://localhost:1338',//'http://192.168.0.42:1338',
  apiItalianoProblemas:
    "https://conciencia.hiperion.com.ar/grec/webservices/proxyItaliano.php?&action=autocomplete_problemas&termino=",
  supportedLanguages: ["en-US", "fr-FR"],
  firebase: {
    apiKey: "AIzaSyDbC_oK9TvdVbLbQUPq9SplQrXBpMgNtOU",
    authDomain: "portal-medico-53cee.firebaseapp.com",
    databaseURL: "https://portal-medico-53cee.firebaseio.com",
    projectId: "portal-medico-53cee",
    storageBucket: "portal-medico-53cee.appspot.com",
    messagingSenderId: "646053228047",
    appId: "1:646053228047:web:975a684154b032eeb33d7a",
    measurementId: "G-QN7J9JMWPM"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
