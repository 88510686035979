import { Component, OnInit, OnDestroy, EventEmitter, NgZone } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";

import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { merge, BehaviorSubject } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import {
  Credentials,
  CredentialsService,
} from "./core/authentication/credentials.service";
import { environment } from "../environments/environment";
import { Logger, I18nService, untilDestroyed } from "../app/core";
import { MessagingService } from "./service/messaging.service";
import { ApiService } from "./core/api.service";
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  HapticsImpactStyle,
  SplashScreen,
  App,
} from "@capacitor/core";
const { Toast, Haptics } = Plugins;
import { DeviceDetectorService } from "ngx-device-detector";
import { Helpers } from "src/app/helpers.service";
const { Device } = Plugins;

const { PushNotifications } = Plugins;
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

const log = new Logger("App");
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  message;
  usuario: any;
  idprofesional: any;
  showHead = false;
  idPacientePortal: number;
  urlPortal: string;
  campanias: any;
  mostrarBotonCampania: any;
  deviceInfo: any;
  capacitorInfo: any;
  cantidad_notificaciones: number;
  actualizarCantidadNotificaciones: number = 1;
  currentMessage = new BehaviorSubject(null);
  constructor(
    private router: Router,
    protected service: ApiService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private credentialsService: CredentialsService,
    private deviceService: DeviceDetectorService,
    private angularFireMessaging: AngularFireMessaging,
    private _snackBar: MatSnackBar,
    private zone: NgZone,
    public snackbar: MatSnackBar

    
  ) {
    this.cantidad_notificaciones =
      this.credentialsService &&
      this.credentialsService.credentials &&
      this.credentialsService.credentials.cantidad_notificaciones
        ? this.credentialsService.credentials.cantidad_notificaciones
        : 0;

    this.usuario =
      this.credentialsService.credentials != undefined &&
      this.credentialsService.credentials != null
        ? this.credentialsService.credentials.nombre
        : undefined;
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event["url"] == "/login" || event["url"] == "/") {
          this.showHead = false;
        } else {
          this.usuario =
            this.credentialsService.credentials != undefined &&
            this.credentialsService.credentials != null
              ? this.credentialsService.credentials.nombre
              : undefined;
          if (
            this.credentialsService.credentials == undefined ||
            this.credentialsService.credentials == null
          ) {
            this.showHead = false;
          } else {
            this.showHead = true;
            this.mensajesPush();
            // Seteamos la url del backend portal
            this.urlPortal = environment.apiPortalSaludEndpoint;
            // Buscamos las campañas y las agregamos en el menu
            this.cargarMenuCamapania();
            this.mostrarBotonCampania = false;
          }
        }
      }
    });
  }

  // Agregamos campañas al menu
  async cargarMenuCamapania() {
    this.campanias = await this.buscarCampanias();
  }

  // Busca las campañas activas
  async buscarCampanias() {
    return new Promise((resolve, reject) => {
      this.service
        .getData(
          this.urlPortal +
            '/api/v1/clinica/cli_campanias?where={"baja_fecha":null}'
        )
        .subscribe((data: any) => {
          let respuesta = [];
          if (data.length > 0) {
            if (data.length > 1) {
              this.mostrarBotonCampania = true;
            }
            respuesta = data;
          }
          resolve(respuesta);
        });
    });
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    SplashScreen.hide();
    log.debug("init");

    // Setup translations
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );

    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  hapticsImpact(style = HapticsImpactStyle.Heavy) {
    Haptics.impact({
      style: style,
    });
  }

  async mensajesPush() {
    await this.getInfoDevice();
    const platform = this.capacitorInfo.platform;
    if (platform != "web") {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener(
        "registration",
        (token: PushNotificationToken) => {
          // Actualizamos el token en el usuario
          if (token.value != this.credentialsService.credentials.token_msj) {
            // Creamos el objeto con el token
            // Actualizamos el token en el usuario
            this.actualizarTokenMsj(
              token.value,
              this.credentialsService.credentials
            );
          }
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotification) => {
          this.show(notification.body);
          this.setCantidadNotificaciones(1);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: PushNotificationActionPerformed) => {
          this.actualizarNotificacionRecibida(notification);
        }
      );
    } else {
      // Está en pc y debemos obtener el token con firebase
      this.angularFireMessaging.messaging.subscribe((_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);

        navigator.serviceWorker.onmessage = function (messageevent) {};

        navigator.serviceWorker.addEventListener("message", (messageevent) => {
          // event is a MessageEvent object
          if (
            messageevent &&
            messageevent.data &&
            messageevent.data.firebaseMessaging &&
            messageevent.data.firebaseMessaging.payload &&
            messageevent.data.firebaseMessaging.payload.notification
          ) {
            this.openSnackBar(
              messageevent.data.firebaseMessaging.payload.notification
            );
          }
        });
      });
    }
    this.requestPermission();
    this.receiveMessage();
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        if (this.credentialsService.credentials.token_msj !== token) {
          this.actualizarTokenMsj(token, this.credentialsService.credentials);
        }
      },
      (err) => {
        // alert('no tiene permiso para recibir msj')
        // console.error('No tiene permisos para recibir mensajes.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messaging.subscribe((messaging: any) => {
      messaging.onMessageCallback = (payload: any) => {
        this.currentMessage.next(payload);
      };
    });
  }

  openSnackBar(message: any) {
    const config = new MatSnackBarConfig();
    config.panelClass = ["mat-primary"];

    config.verticalPosition = "top";
    config.horizontalPosition = "end";
    this.zone.run(() => {
      this.snackbar.open(message.body, "x", config);
    });

  }

  async getInfoDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.capacitorInfo = await Device.getInfo();
  }

  async actualizarNotificacionRecibida(notificacion: any) {
    this.service
      .getData(
        this.urlPortal +
          `/api/v1/log_notificaciones_push?where={"fecha_recibida":null,"baja_fecha":null,"or":[{"usuario_recibe":"${this.credentialsService.credentials.usuario}"},{"token_push_recibe":"${this.credentialsService.credentials.token_msj}"}]}` // add logica de where
      )
      .subscribe(async (data: any) => {
        if (data && data.length > 0) {
          await this.getInfoDevice();
          const datos_log = {
            token_push_recibe: this.credentialsService.credentials.token_msj,
            usuario_recibe: this.credentialsService.credentials.usuario,
            fecha_recibida: Helpers.getDateTimeFullBD(),
            appVersion: this.capacitorInfo.appVersion
              ? this.capacitorInfo.appVersion
              : environment.APP_VERSION,
            appBuild: this.capacitorInfo.appBuild
              ? this.capacitorInfo.appBuild
              : environment.APP_VERSION,
            browser: this.deviceInfo.userAgent,
            operatingSystem: this.deviceInfo.os,
            osVersion: this.capacitorInfo.osVersion,
            device: this.deviceInfo.device,
            usuario: this.credentialsService.credentials.usuario,
            id_app: 2,
            fecha_acceso: Helpers.getDateTimeFullBD(),
            ip: (<HTMLInputElement>document.getElementById("ip")).value,
            manufacturer: this.capacitorInfo.manufacturer,
            platform: this.capacitorInfo.platform,
            model: this.capacitorInfo.model,
          };
          for (let i = 0; i < data.length; i++) {
            datos_log["mensaje"] =
              notificacion && notificacion.mensaje
                ? notificacion.mensaje
                : data[i].mensaje;
            datos_log["notificacion"] =
              notificacion && notificacion.data
                ? notificacion.data
                : data[i].data;

            this.service
              .putData(
                this.urlPortal + "/api/v1/log_notificacion_push",
                data[i].id,
                datos_log
              )
              .subscribe((data: any) => {});
          }
        }
      });
  }
  async show(message: any) {
    this.hapticsImpact(HapticsImpactStyle.Heavy);
    Haptics.vibrate();
    await Toast.show({
      text: message,
      position: "top",
      duration: "long",
    });
  }

  // Actualiza el token en el paciente para mandar los mensajes push
  actualizarTokenMsj(token_push, credentials) {
    this.service
      .getData(
        this.urlPortal +
          '/api/v1/clinica/cli_usuarios?where={"nombre_usuario":"' +
          credentials.usuario +
          '"}'
      )
      .subscribe((usuario) => {
        if (usuario && usuario.length === 0) {
          // No esta registrado en 'Usuario' en el portal, le creamos el usuario
          let options = {
            nombre_usuario: credentials.usuario,
            token_push: token_push,
            id_profesional: credentials.id_profesional_federado,
          };
          this.service
            .postData(
              this.urlPortal + "/api/v1/clinica/new_cli_usuario",
              options
            )
            .subscribe(() => {});
          this.credentialsService.setToken(token_push);
        } else {
          // Ya tiene creado el usuario, actualizamos el token
          let id_usuario = usuario[0].id;
          this.credentialsService.setToken(token_push);
          let options = {
            token_push: token_push,
          };
          this.service
            .putData(
              this.urlPortal + "/api/v1/clinica/cli_usuario",
              id_usuario,
              options
            )
            .subscribe((data: any) => {});
        }
      });
  }

  setCantidadNotificaciones(cantidad_notificaciones: number) {
    this.cantidad_notificaciones = cantidad_notificaciones;
    this.credentialsService.setCantidadNotificaciones(cantidad_notificaciones);
    this.actualizarCantidadNotificaciones = 0;
    this.actualizarCantidadNotificaciones ? 0 : 1;
  }

  ngOnDestroy() {
    this.credentialsService.setCredentials(undefined);
    this.i18nService.destroy();
    this.router.navigate(["login"]).then(async (e) => {});
  }
}
