import { Component, OnInit } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Credentials, CredentialsService } from '../core/authentication/credentials.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from "@angular/material";

@Component({
  selector: 'app-ver-controles',
  templateUrl: './ver-controles.component.html',
  styleUrls: ['./ver-controles.component.scss']
})
export class VerControlesComponent implements OnInit {
  url: any;
  id_paciente_campania: any;
  resultados_cargados: any;  
  cargandoResultados = true;
  constructor(private dialog: MatDialog, private router: Router, activateRoute: ActivatedRoute, protected service: ApiService, private credentialsService: CredentialsService) {
    this.url = environment.apiPortalSaludEndpoint;
    this.id_paciente_campania = (activateRoute.snapshot.params.id);
    this.buscarResultadosCargados();
  }

  ngOnInit() {
  }

  // verificarResultado(resultadoActual:any, resultado_cargado:any, tipo:any){
  //   // tipo 1 es mayor, tipo 2 es menor
  //   let alerta;
  //   resultadoActual = parseFloat(resultadoActual)
  //   resultado_cargado = parseFloat(resultado_cargado)
  //   if (tipo==1){
  //     if (resultadoActual >= resultado_cargado){
  //       alerta = true;
  //     } else {
  //       alerta = false;
  //     }
  //   } else if (tipo==2){
  //     if (resultadoActual < resultado_cargado) {
  //       alerta=true;
  //     } else {
  //       alerta = false;
  //     }
  //   }
  //   return alerta; 
  // }

  // Function donde buscamos los resultados que cargo el paciente
  buscarResultadosCargados() {
    this.service.getData(this.url + '/api/v1/clinica/cli_control_resultado_cargados/' + this.id_paciente_campania).subscribe((data: any[]) => {
      this.resultados_cargados = data;
      this.cargandoResultados = false;
    })

  }
}
