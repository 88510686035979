import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { CredentialsService } from '../core';
import { ApiService } from '../core/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nuevo-pass',
  templateUrl: './nuevo-pass.component.html',
  styleUrls: ['./nuevo-pass.component.scss']
})
export class NuevoPassComponent implements OnInit {
  url: string;
  error: boolean = true;
  nuevoPass!: FormGroup;
  isLoading = false;
  message = "";
  showHead= false;

  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private credentialsService: CredentialsService, protected service: ApiService) { 
    this.createForm();
    this.url = environment.apiPortalSaludEndpoint;
    this.showHead= false;

  }
  


  ngOnInit() {
  }

  cambiaPass() {
    let idPaciente= this.credentialsService.credentials.id_intranet;
    //let idPaciente= this.credentialsService.credentials.id;
    const option = {
      passwordVieja: this.nuevoPass.value.passwordVieja,
      passwordNueva: this.nuevoPass.value.passwordNueva,
      passwordNuevaRepetida: this.nuevoPass.value.passwordNuevaRepetida
    };
    if (option.passwordNueva == option.passwordNuevaRepetida){ 
      if (option.passwordNueva.length >= 8){
        this.service.putData(this.url + '/api/v1/update_pass_cli_paciente/',idPaciente, option).subscribe((data: any[]) => {
          if (data['success'] == true) {
            alert('Cambio de contraseña correcta. ')
            this.router.navigate(['inicio'])
          } else {
            this.error = false;
            this.message = data['message'];
          }
        });
        }else{
          this.error = false;
          this.message = "La contraseña nueva debe tener al menos 8 caracteres."
        }
      }else{
          this.error = false;
          this.message = "La contraseña y la comprobación no coinciden.";
      }
  }
  private createForm() {
    this.nuevoPass = this.formBuilder.group({
      passwordVieja: ['', Validators.required],
      passwordNueva: ['', Validators.required],
      passwordNuevaRepetida: ['', Validators.required],
    }, { validator: this.passwordConfirming });
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('passwordNueva').value !== c.get('passwordNuevaRepetida').value) {
      return { invalid: true };
    }
  }


}
