import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CredentialsService } from '../core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from '../core/api.service';
import { environment } from '../../environments/environment';

export interface DialogData {
  datoActual: string
  datoNuevo: string
  titulo: string
}
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  id: number
  nombre: any
  numeroDocumento: string
  fechaNacimiento: string
  direccion: string
  mail: string
  telefonoCelular: string
  telefonoFijo: string
  urlPortal = environment.apiPortalSaludEndpoint
  datoActual: string
  datoNuevo: string

  constructor(private router: Router, activateRoute: ActivatedRoute, private credentialsService: CredentialsService, public dialog: MatDialog, protected service: ApiService) {
    if (this.credentialsService.credentials == null) {
      //Si no esta logeado lo mandamos al login
      this.router.navigate(['login'])
    } else {
      if (this.credentialsService.credentials != undefined && this.credentialsService.credentials.cambio_password != undefined && this.credentialsService.credentials.cambio_password == 0) {
        this.id = this.credentialsService.credentials.id_intranet;
        //this.id = this.credentialsService.credentials.id
        this.buscarDatosPacientes(this.id)
      } else {
        this.router.navigate(['nuevo-pass'])
      }
    }
  }

  async buscarDatosPacientes(id: any) {
    let datosPaciente: any = await this.buscarPaciente(id)
    this.nombre = datosPaciente.nombre
    this.numeroDocumento = datosPaciente.numero_documento
    this.direccion = datosPaciente.direccion
    this.telefonoCelular = datosPaciente.telefono_celular
    this.mail = datosPaciente.mail
    this.telefonoFijo = datosPaciente.telefono_fijo
    let fecha = datosPaciente.fecha_nacimiento


    //Fecha de nacimiento
    //Parseamos la fecha para que sea mejor visualmente para el usuario
    let dia = fecha.slice(8, 10)
    let mes = fecha.slice(5, 7)
    let anio = fecha.slice(0, 4)
    this.fechaNacimiento = dia + "/" + mes + "/" + anio
  }

  async buscarPaciente(id: any) {
    return new Promise((resolve, reject) => {
      this.service.getData(this.urlPortal + '/api/v1/cli_paciente/' + id).subscribe((data: Array<any>) => {
        resolve(data)
      })
    })
  }

  ngOnInit() {
  }

  editar(numero: any): void {
    let titulo: string
    if (numero == 1) {
      this.datoActual = this.direccion
      titulo = 'direccion'
    }
    if (numero == 2) {
      this.datoActual = this.telefonoCelular
      titulo = 'telefono celular'
    }
    if (numero == 3) {
      this.datoActual = this.telefonoFijo
      titulo = 'telefono fijo'
    }
    const dialogRef = this.dialog.open(EditarDatosComponent, {
      width: '250px',
      data: { datoActual: this.datoActual, numero: numero, titulo: titulo }
    });

    dialogRef.afterClosed().subscribe(result => {
      let actualizo = true
      if (result != this.datoActual && result != undefined){

        if (this.datoActual != '') {
          if (numero != 1) {
            if (!isNaN(result)) {
              actualizo = true
            } else {
              actualizo = false
              alert('El valor ingresado solo debe contener numeros')
            }
          } else {
            if ((result != null) && !(result == "")) {
              actualizo = true
            } else {
              actualizo = false
              alert('La direccion no puede quedar vacio')
            }
          }
        }
        if (actualizo) {
          this.actualizarDato(numero, result)
        }
      }
    });
  }

  actualizarDato(numero: any, dato) {
    let datosActualizados;
    if (numero == 1) {
      datosActualizados = {
        direccion: dato
      }
    }
    if (numero == 2) {
      datosActualizados = {
        telefono_celular: dato
      }
    }
    if (numero == 3) {
      datosActualizados = {
        telefono_fijo: dato
      }
    }
    this.service.putData(this.urlPortal + '/api/v1/update_cli_paciente', this.id, datosActualizados).subscribe((data: any) => {
      this.direccion = data.direccion
      this.telefonoCelular = data.telefono_celular
      this.telefonoFijo = data.telefono_fijo
    })
  }



}

@Component({
  selector: 'editar-datos',
  templateUrl: 'EditarDatosComponent.html',
})
export class EditarDatosComponent {
  datoNuevo: string;
  datoActual: string;
  constructor(
    public dialogRef: MatDialogRef<EditarDatosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}